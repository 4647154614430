import React, { useEffect } from "react";
import "../css/privacyPolicy.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div className="privacy-container">
      <h1 className="text-center"> Privacy Policy</h1>
      <p>
        LipiLabs, a company incorporated under the Companies Act, 2013
        (Hereinafter referred to as “<b>Lipi Labs</b>”, “we”, or “us”) offers
        several services, including our Generative AI Technology for generating
        Contracts, Agreements, and Legal Documents amongst others.
      </p>
      <h2>
        <u>Scope of Policy</u>
      </h2>
      <p>
        When you register/signup with the Website or use the Website, we may
        collect personal and Non-Personal data. Accordingly, whenever you use or
        login with the Website or Service, you agree to the collection, use, and
        disclosure of the data by us in accordance with this Policy.
      </p>
      <h2>
        <u>Consent to Accept Terms of this Agreement</u>
      </h2>
      <p>
        By accessing this website or signing up on this website, (i) you confirm
        that you are eligible to contract, (ii) accept the Terms of Service, and
        the Privacy Policy as displayed on the Website and (iii) you consent to
        receive communications, notices and information from us through post or
        any other physical medium or electronically (whether through SMS,
        emails, phone calls and automated phone calls).
      </p>
      <h2>
        <u>Mode of Collection of Information</u>
      </h2>
      <p>
        We collect information about our users in the following ways:
        <ol>
          <li style={{ listStyle:"lower-alpha"}}>
            {" "}
            Directly from you in different ways on the websites, apps, platforms
            or product or service offerings so that we can:
          </li>
          <li style={{ listStyle:"lower-alpha"}}>from our web server logs,</li>
          <li style={{ listStyle:"lower-alpha"}}>user surveys, or user feedback</li>
          <li style={{ listStyle:"lower-alpha"}}>through cookies, pixels, and server logs,</li>
          <li style={{ listStyle:"lower-alpha"}}>
            {" "}
            we may obtain your information from third parties with whom we may
            have agreements to perform certain functions or services on our
            behalf; and
          </li>
          <li style={{ listStyle:"lower-alpha"}}>
            We may collection information through any other method in accordance
            with Applicable Laws of India, and subject to this agreement.
          </li>
        </ol>
      </p>
      <h2>
        <u>Legal Basis for Collection of your Information</u>
      </h2>
      <p>
        For collecting, processing, using, storing, disclosing or sharing your
        Sensitive Personal Information as described in this Privacy Policy (“
        <b>data processes</b>”), we seek your consent for actions involving data
        processes. Wherever specific consent is required, we will seek such
        specific consent from you from time to time. In relation to information
        obtained from third parties or group entities, we rely on the consent
        taken by these parties on their respective platforms in our favour for
        us to process the information. You may, of course, decline to share
        certain information with us, in which case we may not be able to provide
        to you some or all of the features and functionalities of the Websites,
        Apps and the Platform. This may also affect our ability to process your
        Personal Information (including Sensitive Personal Information) and may
        therefore lead to the non-availability or discontinuation of the
        services for which such Personal Information (including Sensitive
        Personal Information) are to be used or was being used, at our sole
        discretion.
      </p>
      <h2>
        <u>Storage of Information</u>
      </h2>
      <p>
        We will use reasonable safeguards to preserve the integrity and security
        of your information against loss, theft, unauthorized access,
        disclosure, reproduction, use or amendment.
      </p>
      <p>
        We assume no liability for any disclosure of information due to errors
        in transmission, unauthorized third-party access to our website and
        databases or other acts of third parties, or acts or omissions beyond
        our reasonable control, and you shall not be entitled to hold us
        responsible for any breach of security.
      </p>
      <h2>
        <u>
          What is Personal Information and what types of personal information do
          we collect about you?
        </u>
      </h2>
      <p>
        Sensitive Personal information is data that can be used to uniquely
        identify or contact a single person. Any personal information or content
        that you voluntarily disclose for posting on the site (“
        <b>User Content</b>”) becomes available to the public.
      </p>
      <p>
        For the purposes of this Policy, "<b>Sensitive Personal Information</b>"
        includes, but is not limited to, information such as your name, address,
        telephone number, date of birth, e-mail address, Financial Information
        (which includes Credit card, Debit Card or any other instrument’s
        information), location, any data available on your mobile phone or other
        devices, and proof of identity issued by any governmental authority. We
        collect Sensitive Personal Information which may be relevant and
        required for our operations and to understand you and your interests.
      </p>
      <h2>
        <u>Purposes for which we process your Sensitive Personal Information</u>
      </h2>
      <p>We may use this personal information about you to:</p>
      <ol>
        <li style={{ listStyle:"number"}}>
          To verify, authenticate and authorize your use of our products or
          services;
        </li>
        <li style={{ listStyle:"number"}}>To Manage our relationship with you;</li>
        <li style={{ listStyle:"number"}}>
          to authorize and process payment for our services, which may include
          International Payments;
        </li>
        <li style={{ listStyle:"number"}}>to design, offer and deliver products and services;</li>
        <li style={{ listStyle:"number"}}>to advertise on the site;</li>
        <li style={{ listStyle:"number"}}>
          to enable our vendors and contractors to assist us with marketing
          operations;
        </li>
        <li style={{ listStyle:"number"}}>to troubleshoot and examine the site and other services;</li>
        <li style={{ listStyle:"number"}}>
          to analyze data and send more relevant messages to you or other
          website users;
        </li>
        <li style={{ listStyle:"number"}}>
          to study and assess data to enhance our products, services and the
          website;
        </li>
        <li style={{ listStyle:"number"}}>
          to distribute emails with special offers, updates, newsletters,
          customer service and service announcements;
        </li>
        <li style={{ listStyle:"number"}}>
          to protect the integrity of the website, its users and the services
          provided;
        </li>
        <li style={{ listStyle:"number"}}>
          to protect our business and to ensure compliance with the Applicable
          law;
        </li>
        <li style={{ listStyle:"number"}}>
          to disclose and give information to law enforcement agencies,
          Regulatory Authorities when legally required; and
        </li>
        <li style={{ listStyle:"number"}}>
          to respond to judicial processes and provide information to law
          enforcement agencies in connection with an investigation on matters
          relating to public safety, as required by law.
        </li>
      </ol>
      <h2>
        <u>Collection and use of Non-Personal Data</u>
      </h2>
      <p>
        Non-personal information is any data that cannot be used to personally
        identify an individual, such as browser type, IP address, operating
        system, device type, cookies, pixel tags, demographic information, and
        time zone settings.
      </p>
      <p>
        As Non-Personal Information does not personally identify you, we may use
        and disclose Non-Personal Information for purpose of our services.
      </p>
      <h2>
        <u>Disclosure of Information and to whom we disclose</u>
      </h2>
      <p>
        We will keep your Personal Information confidential to the maximum
        possible extent. We shall limit the disclosure of Personal Information
        to our employees, independent contractors, affiliates, consultants,
        business associates, service providers, and distributors of our Service,
        on a need-to-know basis. We may share Personal Information only for the
        purposes as stated above and only to the entities described below.
     
        </p>
        <ul className="mx-5">
          <li style={{listStyle:"square outside none"}}>
            {" "}
            To our Business Affiliates where certain content or service are
            jointly offered by us and our business affiliates; or
          </li>
          <li style={{listStyle:"square outside none"}}>
            {" "}
            to our third party services providers who provide services such as
            contact information verification, payment processing, order
            fulfilment, customer service, website hosting, data analysis,
            marketing assistance, infrastructure provision, IT services,
            auditing services and other similar services to enable them to
            provide the services; or
          </li>
          <li style={{listStyle:"square outside none"}}>
            {" "}
            when required by law enforcement, government officials, fraud
            detection agencies, financial institutions or other third parties
            and when we are compelled to do so under applicable laws (such as
            via a court order, regulatory query or similar procedure).
          </li>
        </ul>
        <h2>
          <u>International Data Transfers</u>
        </h2>
        <p>
          Your Personal Information and Sensitive Personal Information may be
          processed either locally in India or in any other country where our
          approved third-party service providers operate, worldwide, as
          permitted by applicable laws, provided that transaction-related data
          i.e. data pertaining to our products and services provided to you will
          be stored in India. Should your Personal Information (including
          Sensitive Personal Information) move outside India, we will ensure to
          comply with the provisions of applicable laws and will also ensure
          that our Entities or our third-party processors shall also comply with
          the same level of data protection as are applicable in India under
          applicable laws.
        </p>
        <h2>
          <u>Protecting Your Information</u>
        </h2>
        <p>
          We are committed to protecting the security and privacy of the
          personal information we collect. We have implemented appropriate
          technical and organizational measures to protect your information from
          unauthorized access, use, or disclosure. We use security measures such
          as encryption, physical access controls to our data centers,
          authentication tools, and information access authorization controls to
          protect your information.
        </p>
        <h2><u>Children’s Privacy</u></h2>
        <p>
        We understand how important it is to protect the privacy of children. We do not knowingly collect or solicit personal information from children under the age of 13. If a parent or guardian becomes aware that their child has provided us with personal information without their consent, they should contact us at the email id provided on our website. If we become aware that a child under 13 has provided us with personal information, we will delete such information from our files immediately.
        </p>
        <h2>General Provisions</h2>
        <p>
          <strong><u>General Acceptance</u></strong>
        </p>
        <p>
          By accessing the Website or the Service, you have agreed to the terms
          set out in this Policy. This Policy should be at all times read along
          with the User Terms of the Service. Every word capitalized in this
          document shall have the same meaning as that in the Terms of Service.
        </p>
        <p>
          <strong><u>Changes to this Policy</u></strong>
        </p>
        <p>
          We may make changes to this Policy, from time to time at our sole
          discretion or on account of changes in law. The revised Policy shall
          be made available on the Website. Your continued use of the Website
          and the Service, following changes to the Policy, will constitute your
          acceptance of those changes.
        </p>
        <p>
          <strong><u>Applicable Law </u></strong>
        </p>
        <p>
          If you choose to visit the Website, your visit and any dispute over
          privacy are subject to this Policy and the website and service’s User
          Terms, and the Applicable Law shall be the law of the Republic of
          India.
        </p>
        <p>
          <strong><u>Breach, Liability, and Termination</u></strong>
        </p>
        <p>
          This Policy together with the Terms of Service (the “Terms”)
          (collectively, the “<b>Agreement</b>”) constitutes a contract that governs
          your use of the site and/or the service and set forth the terms and
          conditions when you access or use the site or in connection with the
          service, including participation or browsing. If you breach any of the
          terms of the agreement, you have the right to sue the site or right to
          use the service shall stand terminated, and any subsequent use by you
          of the site or service after such termination shall be deemed to be
          unauthorized. Any and all liability arising from such unauthorized use
          shall be borne by you, the user.
        </p>
     
    </div>
  );
};
export default PrivacyPolicy;

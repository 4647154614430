import React, { useState } from 'react';
import "../css/popup.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const { REACT_APP_REDIRECT_URI } = process.env
const Popup = ({ onConfirm, onCancel, text, proceed }) => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(true);

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onCancel();
    };
    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary" role="alert">
                    <div className="popup-container p-5 position-relative">
                        <i onClick={handleCancel} className='far fa-window-close fa-2x position-absolute top-2 right-5 cursor-pointer'></i>
                        <h5 className="mb-2">{text ? text : "Are you sure to delete this file permanently?"}</h5>
                        <div className='space-x-5 text-center'>
                            {!proceed && <button onClick={handleConfirm} className="btn btn-danger my-3 px-4">Delete <i className='fas fa-trash'></i></button>}
                            {proceed && <button onClick={handleConfirm} className="btn btn-primary my-3 px-4">Proceed <i className='fas fa-check'></i></button>}
                            <button onClick={handleCancel} className="btn btn-danger my-3 px-4">Cancel <i className='fas fa-window-close'></i></button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
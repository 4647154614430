import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { IoCloudUploadOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import "../css/customisedTemplate.css"
import { v4 as uuidv4 } from 'uuid';
import UserService from '../../services/UserService';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getNotification } from '../../Redux/Action/action';
import Loader from "react-js-loader";
import customisedTemplate from "../../assets/customisedTemplate.png"
import { Link } from 'react-router-dom';



const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env
const CustomisedTemplate = () => {
    const location = useLocation()
    const receivedData = location.state?.data || null;
    const data = [
        {
            line1: "Answer a few questions, and we will make it happen!",
            line2: "Let's go by your documents one by one.",
            button1: "Next",
            button2: "Home",
            click1: "1",
        },
        {
            line1: "Name the document you want Scripters App to script",
            button1: "Skip",
            button4: "Next",
            click1: "2",
        },
        {
            line1: "Would you want to use your own draft or template as a starting point?",
            button1: "Yes",
            button4: "No",
            button2: "Back",
            button3: true,
            click1: "3",
            click2: "1",
            click3: "4",
        },
        {
            line1: "Upload a template : ",
            button1: "Next",
            button2: "Back",
            button3: true,
            click1: "4",
            click2: "2",
        },
        {
            line1: "Any other information regarding the document or your requirement that you would like to provide:",
            button1: "Skip",
            button2: "Back",
            button4: "Next",
            button3: true,
            click1: "5",
            click2: "2",
        },
        {
            line1: "Please share your phone & email for us to get back to you.",
            button1: "Save and Submit",
            button2: "Back",
            button3: true,
            click2: "4",
        },

    ]
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [no, setNo] = useState("0")
    const [fileUploaded, setFileUploaded] = useState(false);
    const [fileObject, setFileObject] = useState("")
    const [loader, setLoader] = useState(false)
    const [onSucess, setOnSuccess] = useState(false)
    const [onSucessMessage, setOnSuccessMessage] = useState("")

    // useEffect(() => {
    //     document.querySelector(".custom-footer").classList.add("d-none")
    //     return () => {
    //         document.querySelector(".custom-footer").classList.remove("d-none")
    //     };
    // }, [])

    const handleFileUpload = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onloadend = () => {
                const base64 = btoa(reader.result);
                setFileObject(base64);
            };
        };
        setFileUploaded(true);
    }
    const resetFileUpload = () => {
        // Reset the fileUploaded state and show the upload button again
        setFileUploaded(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFileUpload(event.dataTransfer.files[0]);
    };

    const getUserSessionId = () => {
        const token = UserService.getToken()
        const subs = parseJwt(token)
        const sub = subs.sid
        return sub
    }
    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };

    const sendData = async () => {
        const documentName = document.getElementById("documentName").value
        const description = document.getElementById("description").value
        const phoneNo = document.getElementById("phoneNo").value
        const email = document.getElementById("email").value
        if (email == "") {
            dispatch(
                getNotification({
                    message:
                        "Plesae provide email adress!",
                    type: "default",
                }))
        }
        else {
            try {
                // const reader = new FileReader();
                setLoader(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                // sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("template_file", fileObject);
                sendData.append("request_id", uuidv4());
                sendData.append("session_id", UserService.isLoggedIn() ? getUserSessionId() : "");
                sendData.append("user_name", UserService.isLoggedIn() ? UserService.getFullName() : "");
                sendData.append("document_name", documentName);
                sendData.append("description", description);
                sendData.append("other_info", "");
                sendData.append("ph_no", phoneNo);
                sendData.append("email_id", email);
                sendData.append("file_extension", "docx");


                if (phoneNo === "") setOnSuccessMessage("You don’t seem to have provided any details. No worries, our team will contact you via email.")
                else setOnSuccessMessage("Thank you for your interest in adapting the cutting edge of legal AI. Our team will be in touch with you soon!")


                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/upload_file_and_text/`,
                    headers: {
                        // "Accept": "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                    data: sendData,
                };
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    setLoader(false);
                    setOnSuccess(true)
                }
            } catch (err) {
                setLoader(false);
                console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/customised-template` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Sorry, our network is down with too many requests. Please try in sometime!",
                            type: "default",
                        })
                    );
                }
            }
            setLoader(false);
        }
    }

    return (
        <div style={{ zIndex: "99999" }} className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 p-3">
            <div className="w-full max-w-xl overflow-y-auto rounded-3xl bg-white relative px-3 py-0">
                <button style={{ zIndex: "999" }} onClick={() => navigate(receivedData)} className='btn btn-light absolute top-1.5 right-5'>
                    <i style={{ zIndex: "-1" }} className='fas fa-times fa-2x'></i>
                </button>
                <div style={{ width: "100%" }} className="w-full">
                    {
                        onSucess ?
                            <div className="m-3 px-4 mx-auto">
                                <div className="mb-8">
                                    <div className='row'>
                                        <div className='d-flex'>
                                            <div className='text-[#C55EDF]'>
                                                <h1 style={{ fontSize: "35px" }}>Thanks! We’ve got what we needed. We’ll get back to you soon! <i className='fas fa-check-circle'></i></h1>
                                            </div>
                                            <div>
                                                <img style={{ maxWidth: "70VW" }} src={customisedTemplate} alt="Customised Template" />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <p className="text-gray-600">
                                        {onSucessMessage}
                                    </p>
                                </div>
                                <div className="space-y-4 text-center">
                                    <button
                                        onClick={() => {
                                            // Reset the form and navigate to the next step or another page if needed
                                            setOnSuccess(false);
                                            navigate(receivedData)
                                        }}
                                        className="btn-outline-purple-custom rounded px-3"
                                    >
                                        Go Home
                                    </button>
                                </div>
                            </div>
                            :
                            loader ?
                                <div className="m-8 my-20 max-w-[400px] mx-auto">
                                    <div className="mb-8">
                                        <div style={{ marginTop: "20vh" }} className="md:col-span-2 md:mt-0">
                                            <Loader
                                                type="bubble-loop"
                                                bgColor={"#000000"}
                                                title={"Sending..."}
                                                color={"#000000"}
                                                size={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="m-3 mx-auto">
                                    <div className={no == 0 ? "col-span-6 sm:col-span-3" : "d-none"}>
                                        <div className='row px-4 py-2'>
                                            <div className='d-flex'>
                                                <div>
                                                    <h1 style={{ fontSize: "30px" }} className='text-[#C55EDF]'>Use Scripters App to customise your templates or generate new ones as your exclusive Scripters!</h1>
                                                </div>
                                                <div>
                                                    <img style={{ maxWidth: "70VW" }} src={customisedTemplate} alt="Customised Template" />
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div className="row px-2 py-2 text-center">
                                            <p>Start by telling us about the document you want scripted.</p>
                                            <p className='text-secondary'>This information lets our team construct a workflow and schedule a free consultation with you.</p>
                                            <div className='text-center'>
                                                <button
                                                    onClick={() => {
                                                        if (data[no].button1 === "Save and Submit") sendData()
                                                        else setNo(data[no].click1)
                                                    }}
                                                    className='px-4 py-2 text-white bg-[#C55EDF] rounded-md'>Let's begin</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={no == 1 ? "col-span-6 sm:col-span-3 mb-4" : "d-none"}>
                                        <div>
                                            <p>Create Exclusive Scripter (1/4)
                                                <hr />
                                            </p>
                                            <h1 style={{ fontSize: "25px" }} className="text-3xl font-extrabold text-center">{data[no].line1 && data[no].line1}</h1>
                                            <p className="text-gray-600">{data[no].line2 && data[no].line2}</p>
                                            <input
                                                type="text"
                                                placeholder="Enter the name of the document"
                                                id="documentName"
                                                autoComplete="given-name"
                                                className="mt-1 block w-2/3 mx-auto rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div>
                                            <div className="space-y-4 text-center mt-1">
                                                <button onClick={() => {
                                                    if (data[no].button1 === "Save and Submit") sendData()
                                                    else setNo(data[no].click1)
                                                }} className="btn-outline-purple-custom bg- mx-3 px-4 rounded-md">{data[no].button1}</button>
                                                <button onClick={() => {
                                                    setNo(data[no].click1)
                                                }} className="btn-outline-purple-custom bg-[#C55EDF] text-white mx-3 px-4 rounded-md">{data[no].button4}</button>

                                                <button onClick={() => navigate(receivedData)} className={data[no].button3 ? "p-3 bg-[#ED1D24] border rounded-full w-full font-semibold text-white mt-4" : "d-none"}>Close</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={no == 2 ? "col-span-6 sm:col-span-3" : "d-none"}>
                                        <p>Create Exclusive Scripter (2/4)
                                            <hr />
                                        </p>
                                        <h1 style={{ fontSize: "25px" }} className="text-3xl font-extrabold text-center">{data[no].line1 && data[no].line1}</h1>
                                        <p className="text-gray-600">{data[no].line2 && data[no].line2}</p>
                                        <div className="space-y-4 text-center mt-1">
                                            <button onClick={() => {
                                                if (data[no].button1 === "Save and Submit") sendData()
                                                else setNo(data[no].click1)
                                            }} className="btn-outline-purple-custom bg- mx-3 px-4 rounded-md">{data[no].button1}</button>
                                            <button onClick={() => {
                                                setNo(data[no].click3)
                                            }} className="btn-outline-purple-custom bg-[#C55EDF] text-white mx-3 px-4 rounded-md">{data[no].button4}</button>
                                        </div>
                                    </div>


                                    <div className={no == 3 ? "col-span-6 sm:col-span-3" : "d-none"}>
                                        <p>Create Exclusive Scripter (2/4)
                                            <hr />
                                        </p>
                                        <h1 style={{ fontSize: "25px" }} className="text-3xl font-extrabold text-center">{data[no].line1 && data[no].line1}</h1>
                                        <p className="text-gray-600">{data[no].line2 && data[no].line2}</p>
                                        {no === "3" && !fileUploaded && ( // Show the upload button if file is not uploaded
                                            <div onDragOver={handleDragOver}
                                                onDragEnter={handleDragEnter}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                className="upload-button-container mb-4">
                                                <label className="upload-button-label">
                                                    <input
                                                        type="file"
                                                        accept=".docx"
                                                        onChange={(event) => handleFileUpload(event.target.files[0])}
                                                    />
                                                    <IoCloudUploadOutline className="upload-icon" />
                                                    <p>Drag & drop a .docx file here, or click to browse</p>
                                                </label>
                                            </div>
                                        )}
                                        {no === "3" && fileUploaded && (
                                            <div className="upload-confirmation text-center">
                                                <p className='d-flex justify-content-center mb-4' >File uploaded successfully! <IoCheckmarkCircleOutline className="upload-checkmark-icon text-success mt-1.5 mx-2" /></p>
                                                <div className='mb-4'>
                                                    <button onClick={resetFileUpload} className="text-[#C55EDF]">
                                                        Upload a different File
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        <div className="space-y-4 text-center mt-1">
                                            <button onClick={() => {
                                                if (data[no].button1 === "Save and Submit") sendData()
                                                else setNo(data[no].click1)
                                            }} className="btn-outline-purple-custom bg- mx-3 px-4 rounded-md">{data[no].button1}</button>
                                            <button onClick={() => {
                                                setNo(data[no].click2)
                                            }} className="btn-outline-purple-custom bg-[#C55EDF] text-white mx-3 px-4 rounded-md">{data[no].button2}</button>
                                        </div>
                                    </div>
                                    <div className={no == 4 ? "col-span-6 sm:col-span-3 mb-4" : "d-none"}>
                                        <p>Create Exclusive Scripter (3/4)
                                            <hr />
                                        </p>
                                        <h1 style={{ fontSize: "25px" }} className="text-3xl font-extrabold text-center">{data[no].line1 && data[no].line1}</h1>
                                        <p className="text-gray-600">{data[no].line2 && data[no].line2}</p>
                                        <textarea
                                            type="text"
                                            placeholder="Describe here"
                                            id="description"
                                            autoComplete="given-name"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <div className="space-y-4 text-center mt-1">
                                            <button onClick={() => {
                                                if (data[no].button1 === "Save and Submit") sendData()
                                                else setNo(data[no].click1)
                                            }} className="btn-outline-purple-custom bg- mx-3 px-4 rounded-md">{data[no].button1}</button>
                                            <button onClick={() => {
                                                setNo(data[no].click1)
                                            }} className="btn-outline-purple-custom bg-[#C55EDF] text-white mx-3 px-4 rounded-md">{data[no].button4}</button>
                                        </div>
                                    </div>
                                    <div className={no == 5 ? "col-span-6 sm:col-span-3" : "d-none"}>
                                        <p>Create Exclusive Scripter (4/4)
                                            <hr />
                                        </p>
                                        <h1 style={{ fontSize: "25px" }} className="text-3xl font-extrabold text-center">{data[no].line1 && data[no].line1}</h1>
                                        <p className="text-gray-600">{data[no].line2 && data[no].line2}</p>
                                        <input
                                            type="text"
                                            placeholder="Email address"
                                            id="email"
                                            autoComplete="given-name"
                                            defaultValue={UserService.isLoggedIn() ? UserService.getEmail() : ""}
                                            className="my-2 block w-2/3 mx-auto rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Phone number"
                                            id="phoneNo"
                                            autoComplete="given-name"
                                            className="my-2 block w-2/3 mx-auto rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        <div className="space-y-4 text-center mt-4">
                                            <button onClick={() => {
                                                if (data[no].button1 === "Save and Submit") sendData()
                                                else setNo(data[no].click1)
                                            }} className="btn-outline-purple-custom mx-3 px-4 rounded-md">{data[no].button1}</button>
                                        </div>
                                    </div>
                                    <div className='my-5'>
                                        <div className={no == 0 || no == 1 ? "d-none" : "space-y-4 text-center absolute bottom-0 left-2"}>
                                            <button onClick={() => {
                                                if (data[no].button2 === "Home") navigate(receivedData)
                                                else setNo(data[no].click2)
                                            }} className="text-[#C55EDF] px-4 rounded-md p-3">
                                                &#8592; {data[no].button2}</button>
                                        </div>
                                    </div>
                                </div>}
                </div>
            </div>
        </div >
    )
}

export default CustomisedTemplate
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserService from './services/UserService';
import HttpService from './services/HttpService';
import { Provider } from 'react-redux';
import store from './Redux/Store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = () => root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// renderApp()
UserService.initKeycloak(renderApp);
HttpService.configure();
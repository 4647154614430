import React, { useEffect, useState } from 'react';
import "../css/popup.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "react-js-loader";

const { REACT_APP_REDIRECT_URI } = process.env
const LoaderPopup2 = () => {
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })

    return (
        <div className="popup-overlay alert alert-secondary" role="alert">
            <div className="popup-container p-5 position-relative">
                <div className='space-x-5 text-center'>
                    <div
                        id="my-agreement-spinner"
                        style={{ marginTop: "10vh" }}
                        className="md:col-span-2 md:mt-0"
                    >
                        <Loader
                            type="bubble-ping"
                            bgColor={"#000000"}
                            title={"Saving..."}
                            color={"#000000"}
                            size={100}
                        />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoaderPopup2;
import React, { useState, useEffect } from 'react';
import '../css/contactus.css';
import { IoCloudUploadOutline, IoCheckmarkCircleOutline } from 'react-icons/io5';
import UserService from '../../services/UserService';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getNotification } from '../../Redux/Action/action';
import Loader from "react-js-loader";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env

const ContactUS = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const receivedData = location.state?.data || null;
  const [fileUploaded, setFileUploaded] = useState(false);

  const [fileObject, setFileObject] = useState("")
  const [loader, setLoader] = useState(false)
  const [onSucess, setOnSuccess] = useState(false)

  useEffect(() => {
    // document.querySelector(".custom-footer").classNameList.add("d-none")
    // return () => {
    //   document.querySelector(".custom-footer").classNameList.remove("d-none")
    // };
  }, [])
  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadend = () => {
        const base64 = btoa(reader.result);
        setFileObject(base64);
      };
    };
    setFileUploaded(true);
  }
  const resetFileUpload = () => {
    // Reset the fileUploaded state and show the upload button again
    setFileUploaded(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event.dataTransfer.files[0]);
  };


  const sendData = async (event) => {
    event.preventDefault();
    const name = document.getElementById("name").value
    const phone = document.getElementById("phone").value
    const mail = document.getElementById("mail").value
    const query = document.getElementById("query").value

    if (name === "") {
      dispatch(
        getNotification({
          message:
            "Plesae provide your name!",
          type: "default",
        }))
    }
    else if (mail === "") {
      dispatch(
        getNotification({
          message:
            "Plesae provide your email adress!",
          type: "default",
        }))
    }
    else {
      try {
        // const reader = new FileReader();
        setLoader(true);
        const FormData = require("form-data");
        const sendData = new FormData();
        // sendData.append("keycloak_user_id", UserService.getUserId());
        sendData.append("document", fileObject);
        sendData.append("document_name", "attached file");
        sendData.append("document_extension", "docx");
        sendData.append("user_name", name);
        sendData.append("email_id", mail);
        sendData.append("query", query);
        sendData.append("phone_number", phone);
        sendData.append("request_id", uuidv4());

        // if (phoneNo === "") setOnSuccessMessage("You don’t seem to have provided any details. No worries, our team will contact you via email.")
        // else setOnSuccessMessage("Thank you for your interest in adapting the cutting edge of legal AI. Our team will be in touch with you soon!")


        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/contact_us`,
          headers: {
            // "Accept": "application/json",
            "Content-Type": "multipart/form-data",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        if (getResponse.status === 200) {
          setLoader(false);
          setOnSuccess(true)
        }
      } catch (err) {
        setLoader(false);
        console.log("Error", err);
        if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Plesae log in again",
              type: "default",
            })
          );
          setTimeout(() => {
            UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/customised-template` });
          }, 3000);
        }
        else {
          dispatch(
            getNotification({
              message: "Sorry, our network is down with too many requests. Please try in sometime!",
              type: "default",
            })
          );
        }
      }
      setLoader(false);
    }
  }


  return (
    <div className="contact-popup">
      <div className="popup p-3">
        <div className="popup-content mt-5">
          {loader ?
            <div className="m-8 my-20 max-w-[400px] mx-auto w-80">
              <div className="mb-8">
                <div style={{ marginTop: "15vh" }} className="md:col-span-2 md:mt-0">
                  <Loader
                    type="bubble-loop"
                    bgColor={"#000000"}
                    title={"Sending..."}
                    color={"#000000"}
                    size={100}
                  />
                </div>
              </div>
            </div>
            :
            onSucess ?
              <div className="m-8 my-20 max-w-[400px] mx-auto">
                <div className="mb-8">
                  <IoCheckmarkCircleOutline className="success-icon text-green-500 text-6xl mx-auto" />
                  <h1 className="mb-4 text-3xl font-extrabold">Success!</h1>
                  <p className="text-gray-600">
                    Thank you for your interest in adapting the cutting edge of legal AI. Our team will be in touch with you soon!
                  </p>
                </div>
                <div className="space-y-4">
                  <button
                    onClick={() => {
                      // Reset the form and navigate to the next step or another page if needed
                      setOnSuccess(false);
                      navigate("/app")
                    }}
                    className="text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl"
                  >
                    Go Home
                  </button>
                </div>
              </div>
              :
              <div className="form bg-white p-6 relative">
                <div className="icon btn-primary text-white w-6 h-5 absolute flex items-center justify-center p-4" style={{ left: "-40px" }}><i className="fas fa-address-book fa-fw text-2xl"></i></div>
                <h3 className="text-2xl text-gray-900 font-semibold">Get in Touch</h3>
                <p className="text-gray-600"> Let us know how we can assist you!</p>
                <div className="flex space-x-5 mt-1">
                  <input type="text" name="" id="name" placeholder="Your Name (Required)" className="border p-2  w-1/2" />
                  <input type="tel" name="" id="phone" placeholder="Your Number" className="border p-2 w-1/2" />
                </div>
                <input type="email" name="" id="mail" placeholder="Your Email (Required)" className="border p-2 w-full mt-3" />
                {!fileUploaded && ( // Show the upload button if file is not uploaded
                  <div
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className="upload2-button-container mt-3">
                    <label className="upload2-button-label">
                      <input
                        type="file"
                        accept=".docx"
                        onChange={(event) => handleFileUpload(event.target.files[0])}
                      />
                      <IoCloudUploadOutline className="upload-icon" />
                      <p>Drag & drop a .docx file here, or click to browse</p>
                    </label>
                  </div>
                )}
                {fileUploaded && (
                  <div className="upload2-confirmation text-center">
                    <p className='d-flex justify-content-center mt-3' >File uploaded successfully! <IoCheckmarkCircleOutline className="upload-checkmark-icon text-success mt-1.5 mx-2" /></p>
                    <div className='mb-4'>
                      <button onClick={resetFileUpload} className="upload-reset-button p-3 bg-white border rounded w-full font-semibold">
                        Upload Another File
                      </button>
                    </div>
                  </div>
                )}
                <textarea name="" id="query" cols="10" rows="3" placeholder="Tell us about your query" className="border p-2 mt-3 w-full"></textarea>
                <button className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl">Submit</button>
                <button onClick={() => navigate(receivedData)} className="mt-6 text-white font-semibold px-5 py-2 btn-primary text-2xl rounded-2xl ml-2">Cancel</button>
              </div>
          }
        </div>
      </div>
    </div>
  );
};

export default ContactUS;

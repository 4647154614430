import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'

import logo from "../../assets/Logo-1.png"

const FooterOthers = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <div className='w-full bg-[#EEF1FB] p-4'>
            <div className="row">
                <div className='col-sm mx-1 mt-2 flex'>
                    <div className='row m-0 p-0'>
                        <div className="col-lg-3 flex justify-content-center sm:block">
                            <img style={{ width: "400px" }} className="black-image" src={logo} alt="logo" />
                        </div>
                        <div className="col-lg-9">
                            <span className='sm:ml-3 mt-0.5 text-black' style={{ opacity: "0.5" }}>a Lipi Labs Product</span>
                        </div>
                    </div>
                </div>
                <div className='col-sm flex justify-content-around'>
                    <span onClick={() => navigate("/app/contact-us", { state: { data: location.pathname } })} className='cursor-pointer text-black mt-3'>
                        <img loading="lazy" width="20" height="20" src="https://img.icons8.com/fluency/20/mail--v1.png" alt="mail--v1" />
                        <span className='position-relative -top-5 left-6'>
                            Contact Us : help@scripters.app
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default FooterOthers
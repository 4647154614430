export const formdata = [
    {
        name: "Information for Claims:",
        placeholder: "Insert instructions and information relevant to drafting your claims, either from the IDF or your notes. The simple claims generator works best with simple human readable instructions on how you want the claims to be structured. Once generated, you can iterate by altering the inputs or editing on the screen.",
        id: "claims_invention",
        type: "textarea",
        element: "input-text",
        required: true,
        optional: false,
    },
    {
        name: "Type of Claims:",
        placeholder: "Device, Method, System, Process, Composition, etc.",
        id: "number_claims",
        type: "textarea",
        row: 3,
        element: "input-text",
        required: false,
        optional: false,
    },
    {
        name: "Any other information or instructions:",
        placeholder: "If there are things you want taken care of which are strictly not relevant for the contents of the draft but affect the logic of the claims, this is where you can pass such instructions. For example: Focus the claims on how the device interacts with the system.",
        id: "addl_info_claims",
        type: "textarea",
        row: 3,
        element: "input-text",
        required: false,
        optional: false,
    },
]



export const fieldNames = [
    "claims_invention",
    "number_claims",
    "addl_info_claims"
];


export const storageName = "masterClaims"


export const requiredCheck = [{
    field: "claims_invention",
    Message: "Please fill the Description of Claims:"
},]

export const agreementType = "Claims Generator – Simple."

export const api = "/api/claims"
export const apiRedirection = "/app/simple-claim"
export const downloadDocName = "Claims_"

export const nextTo = "/app/title-and-technical-field"
export const previousTo = "/app/dfd"
export const formdata = [
    {
        name: "Draft of Claims",
        placeholder: "Insert draft of the claims or generate claims using the Claims Scripter and you will see it here. ",
        id: "claims_draft",
        type: "textarea",
        element: "input-text",
        required: true,
        optional: false,
    },
]



export const fieldNames = [
    "claims_draft",
];


export const storageName = "masterAbstract"


export const requiredCheck = [{
    field: "claims_draft",
    Message: "Please fill the Draft of Claims"
}]

export const agreementType = "Abstract"

export const api = "/api/abstract"
export const apiRedirection = "/app/abstract"
export const downloadDocName = "Abstract_"

export const nextTo = "/app/drawings-and-ddpe"
export const previousTo = "/app/summary"
import React, { useEffect } from 'react'
import "../../components/css/landingpage.css"
import img1 from "../../assets/sittingattable.png"
import img2 from "../../assets/formSS.png"
import img3 from "../../assets/homeSS.png"
import { useDispatch } from 'react-redux'
import { getNavbar } from '../../Redux/Action/action'
import { redirect, useNavigate } from 'react-router'
import UserService from '../../services/UserService'
import FooterLanding from './FooterLanding'


import firstsectionbackground from "../../assets/firstsectionbackground.png"
import firstsectiongroup from "../../assets/firstsectiongroup.png"
import scriptListSS from "../../assets/scriptListSS.jpg"
import groupIcon from "../../assets/groupIcon.png"
import middleSS from "../../assets/middleSS.jpg"
import bussinessGroup from "../../assets/bussinessGroup.png"
import documentLibrary from "../../assets/draftReadyToGo2.png"
import forEveryone from "../../assets/forEveryone.png"
import scriptListBackground from "../../assets/scriptListBackground.png"
import scriptListBackgroundRight from "../../assets/scriptListBackgroundRight.png"
import { useLocation } from 'react-router'
import MyCarousel from './MyCarousel'


const { REACT_APP_REDIRECT_URI, REACT_APP_TIPSY_URL } = process.env;
const LandingPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    // const state = useSelector((state) => state.stateReducer);
    // useEffect(() => {
    //     hideNav()
    //     return () => dispatch(getNavbar(true))
    // }, [])
    // const hideNav = () => {
    //     dispatch(getNavbar(false))
    // }

    return (
        <section>
            <div className='container-fluid'>
                <div style={{ marginBottom: "90px" }}>
                    <div className='row' style={{ padding: "0px 0px 0px 8vw" }}>
                        <div className="col-xl">
                            <div className='powerful-div'>
                                <h1 className='fw-bold custom-landing-heading'>AI Powered end-to-end Drafting Automation</h1>
                                <section>
                                    <MyCarousel />
                                </section>
                                <button onClick={() => {
                                    if (UserService.isLoggedIn()) navigate("/app")
                                    else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + "/app"}` })
                                }} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-xl my-5'>
                                    Try a Scripters App Now
                                    <svg className='mt-2 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="col-xl">
                            <img loading="lazy" src={firstsectionbackground} alt="first section background" />
                            <img loading="lazy" className='absolute top-0 right-0' src={firstsectiongroup} alt="first section group" />
                        </div>
                    </div>
                </div>
                <div className='bg-[#F2F4FC]' style={{ marginTop: "", height: "800px" }}>
                    <br /><br /><br />
                    <div>
                        <div className='row' style={{ padding: "0px 0px 0px 8vw" }}>
                            <div className="col-sm">
                                <div className='pl-5 powerful-div'>
                                    <h1 className='fw-bold custom-landing-heading'>Drafting has never been this easy! </h1>
                                    <h3> Script: Patent Applications.</h3>
                                    <p className='text-secondary' style={{ fontSize: "25px", width: "78%" }}>A Scripters App is your specially designed AI playground optimised to generate high-
                                        quality results. Just add basic details, and get your documents in seconds!</p>
                                    <div>
                                    </div>
                                    <button onClick={() => {
                                        if (UserService.isLoggedIn()) navigate("/app")
                                        else UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + "/app"}` })
                                    }} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full d-flex text-xl'>
                                        Try Scripters App for Free
                                        <svg className='mt-2 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="col-sm flex items-center mt-5">
                                <img loading="lazy" className='rounded z-10' style={{ width: "75%", }} src={middleSS} alt="Middle SS" />
                                <svg className='absolute right-0 -top-14 custom-position-explore' style={{ marginTop: "18vh" }} width="310" height="383" viewBox="0 0 310 383" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 49.9998C0 22.3855 22.3858 -0.000244141 50 -0.000244141H310V383H50C22.3858 383 0 360.614 0 333V49.9998Z" fill="url(#paint0_linear_376_5398)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_376_5398" x1="65.2845" y1="-0.000263144" x2="387.65" y2="83.608" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#4C6EF5" />
                                            <stop offset="0.630208" stopColor="#B871FE" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                {/* <img loading="lazy" className='custom-position-group' src={firstsectiongroup} alt="first section group" /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='for-business'>
                    <br /><br /><br /><br />
                    <h1 className='fw-bold text-center custom-landing-heading'>Scripters Apps For Businesses</h1>
                    <p className='text-center' style={{ fontSize: "20px", }}>Customised templates according to your business needs</p>
                    <div className='mt-5 d-flex flex-column align-items-center'>
                        <img loading="lazy" className='custom-image-margin' style={{ width: "70%", zIndex: "22", }} src={bussinessGroup} alt="Business Group" />
                        {/* <img loading="lazy" className="" src={scriptListSS} alt="Script List SS" /> */}
                    </div>
                    {/* <div>
                    <h1 className='mb-4 fw-bold text-center' style={{ fontSize: "50px", }}>Your Templates, Design and Knowledge</h1>
                    <p className='text-center' style={{ fontSize: "20px", }}>Our Workflows, AI Powered Platform, Accuracy, and Speed!</p>
                </div> */}
                    <div className='' style={{ padding: "0px 0px 0px 8vw", marginTop: "-20vh", }}>
                        <img loading="lazy" style={{ width: "100%", }} src={documentLibrary} alt="Document Library" />
                        <div className='text-center my-3'>
                            <button onClick={() => navigate("/app/customised-template", { state: { data: location.pathname } })} className='mb-4 text-white font-bold py-3 px-4 rounded-full text-xl bg-gradient-to-r from-blue-500 via-purple-500 to-purple-400'>
                                <span className='flex'>
                                    Explore Scripters Apps for Business
                                    <svg className='mt-2 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        {/* <img loading="lazy" className="" src={scriptListSS} alt="Script List SS" /> */}
                    </div>
                </div>
                <div className='bg-[#F2F4FC]' style={{ marginTop: "", height: "1500px" }}>
                    <br /><br /><br />
                    <div>
                        <h1 className='mb-4 fw-bold text-center' style={{ fontSize: "50px", }}>Power your drafting and editing. Automate. Innovate</h1>
                        <p className='text-secondary text-center' style={{ fontSize: "20px", }}>Experience 6 pre-built AI Scripters Apps for free and let us build one for you!</p>
                    </div>
                    <div className='position-relative flex flex-col items-center justify-center' style={{ padding: "6vw", zIndex: "999" }}>
                        <img loading="lazy" style={{ width: "100%", }} src={scriptListSS} alt="front page" />
                        <button onClick={() => navigate("/app")} className='bg-blue-500 hover:bg-blue-700 text-white -mt-5 font-bold py-3 px-4 rounded-full text-xl flex'>
                            Try a Scripters App Now
                            <svg className='mt-2 mx-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                    <img loading="lazy" style={{ position: "absolute", top: "450vh", left: "0", }} src={scriptListBackground} alt="Script List SS background" />
                    <img loading="lazy" style={{ position: "absolute", top: "460vh", right: "0", }} src={scriptListBackgroundRight} alt="Script List SS background right" />
                    <div className='mt-5 d-flex flex-column align-items-center position-relative for-all'>
                        <h1 className='fw-bold text-center' style={{ fontSize: "35px", }}>For Everyone. For Free.</h1>
                        <img loading="lazy" className="" src={groupIcon} alt="Group Icon" />
                        <img loading="lazy" style={{ width: "38%", position: "absolute", right: "0", top: "-40vh" }} src={forEveryone} alt="For Everyone" />
                    </div>
                    <div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <FooterLanding />
        </section>
    )
}

export default LandingPage
import React, { useRef } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import UserService from "../../services/UserService";
import "../css/style.css";
import "../css/navbar.css"
import navbarBackgroundLeft from "../../assets/navbarBackgroundLeft.png"
import navbarBackgroundRight from "../../assets/navbarBackgroundRight.png"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/Logo-1.png"
const { REACT_APP_REDIRECT_URI, REACT_APP_TIPSY_URL } = process.env;
function NavBar() {
  const location = useLocation();
  const state = useSelector((state) => state.stateReducer);
  const [scrolling, setScrolling] = useState(false);
  const [landingPage, setLandingPage] = useState(true)
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate()
  const buttonRef = useRef(null);



  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  useEffect(() => {
    handleLocationChange();
  }, [location])

  const handleLocationChange = () => {
    if (location.pathname !== "/") setLandingPage(false)
    else setLandingPage(true)
  }


  function isMobileView() {
    // Get the width of the screen
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // Define a breakpoint for mobile devices (adjust as needed)
    const mobileBreakpoint = 768;

    // Check if the screen width is less than the mobile breakpoint
    return screenWidth < mobileBreakpoint;
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the clicked element is the button or inside the div
      if (
        buttonRef.current &&
        buttonRef.current.contains(event.target) &&
        !event.target.classList.contains('ignore-click-outside')
      ) {
        // Click is inside the button, toggle div visibility
      } else {
        // Click is outside the button and the div, hide the div
        setDropdownOpen(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('click', handleDocumentClick);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isDropdownOpen]);

  return (
    <header className={`navbar ${scrolling ? 'solid' : 'transparent'} container-fluid sticky-top navbar-expand-lg ${landingPage ? "position-fixed" : "solid"} navbar-light navbar-custom  w-full py-3`}>
      {/* <header className={`navbar ${scrolling ? 'solid' : 'transparent'} sticky-top navbar-expand-lg position-fixe navbar-light navbar-custom  w-full py-3`}> */}
      <div className="container-fluid mx-auto flex flex-wrap flex-col md:flex-row items-cen position-relative">
        {
          scrolling ?
            <img style={{ width: "150px" }} onClick={() => navigate("/")} className="cursor-pointer" src={logo} alt="logo" />

            :
            <img style={{ width: "150px" }} onClick={() => navigate("/")} className="black-image cursor-pointer" src={logo} alt="logo" />
        }
        <img loading="lazy" className=".custom-position-explor" style={{ position: "absolute", right: "0", zIndex: "-2" }} src={navbarBackgroundLeft} alt="Navbar Background Left" />
        <img loading="lazy" style={{ position: "absolute", right: "65vw", zIndex: "-2" }} src={navbarBackgroundRight} alt="Navbar Background Right" />
        <div className="zIndex-2 flex scale-75 sm:scale-100" id="navbarSupportedContent">
          <ul className="ms-auto my-auto flex items-center border rounded-full pt-3 h-12 md:h-12 sm:h-12" style={{ backgroundColor: "rgba(255, 255, 255, 0.2)", outline: "none" }}>
            {/* <li className={landingPage ? "nav-item active mt-1 mx-2" : "d-none"}>
              <button onClick={() => {
                document.querySelector(".for-all").scrollIntoView();
              }} className="nav-link text-black">
                Tipsytom is free, Try now
              </button>
            </li> */}
            <li className={landingPage ? "nav-item active mt-1 mx-2" : "d-none"}>
              <button onClick={() => {
                navigate("/app")
              }} className="nav-link text-black">
                For All
              </button>
            </li>
            <li className={landingPage ? "nav-item active mt-1 mx-1" : "d-none"}>
              <button onClick={() => {
                document.querySelector(".for-business").scrollIntoView({ behavior: "smooth", block: "start", })
              }} className="nav-link text-black mr-2">
                For Businesses
              </button>
            </li>
            <li className={landingPage ? "d-none" : "nav-item active mt-1 mx-1"}>
              <Link className="nav-link text-black" to="/app">
                Home
              </Link>
            </li>
            <li className={UserService.isLoggedIn() ? "nav-item active mt-1 mr-1" : "d-none"}>
              <Link className="nav-link text-black" to="app/library">
                My Documents
              </Link>
            </li>
          </ul>
          <button onClick={() => UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app" })} className={UserService.isLoggedIn() ? "d-none" : "bg-white rounded-full mx-1 ml-2 p-0 hover:bg-white-700"}>
            <Link className="nav-link text-black text-sm">
              Sign In
            </Link>
          </button>
          <button
            className={UserService.isLoggedIn() ? "m-0 ml-2 mr-1 text-black text-start" : "d-none"}>{UserService.getUsername() && UserService.getUsername().length >= 10 ? UserService.getUsername().slice(0, 10) + "..." : UserService.getUsername()}
            <br />
          </button>
          <div className="relative">
            <button
              ref={buttonRef}
              style={{ backgroundColor: "rgba(255, 255, 255, 0.4)", outline: "none" }}
              spiketip-title="Menu"
              spiketip-pos="left"
              onClick={() => {
                setDropdownOpen(!isDropdownOpen)
              }}
              className="rounded-full mx-2 p-1"
            >
              <Link className="nav-link text-black text-md">
                <i className="fas fa-ellipsis-v"></i>
              </Link>
            </button>
            {isDropdownOpen && (
              <div className="ignore-click-outside absolute z-10 right-0 mt-2 bg-white rounded-xl shadow-lg w-48">
                <ul className="text-center my-0">
                  <li className="m-0 hover:bg-gray-100 rounded-xl">
                    <button onClick={() => navigate("/app/contact-us", { state: { data: location.pathname } })} className="block px-4 py-2 text-sm text-gray-700">
                      <i className="fas fa-headset mr-2"></i>
                      Support
                    </button>
                  </li>
                  <li className={UserService.isLoggedIn() ? "m-0 hover:bg-gray-100 rounded-xl" : "d-none"}>
                    <button onClick={() => UserService.doLogout({ redirectUri: REACT_APP_REDIRECT_URI })} className="block px-4 py-2 text-sm text-gray-700">
                      <i className="fas fa-sign-out-alt mr-2"></i>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {/* <button spiketip-title="Sign out" spiketip-pos="bottom" onClick={() => UserService.doLogout({ redirectUri: `${REACT_APP_REDIRECT_URI}` })}
            className={UserService.isLoggedIn() ? "bg-white rounded-full mx-1 p-1 hover:bg-white-700" : "d-none"}>
            <Link className="nav-link text-black text-md">
              <i className="fas fa-sign-out-alt"></i>
            </Link>
          </button> */}
        </div>
      </div >
    </header >
  );
}

export default NavBar;


import React from 'react'
import { Link } from 'react-router-dom';
import backgroundImageNav from "../../assets/backgroundImageNav.png"
import backgroundImageNav2 from "../../assets/backgroundImageNav2.png"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'


const FooterLanding = () => {
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <div className='relative bg-[#4153D2] w-full' style={{ padding: "5vw 8vw 2vw 8vw", backgroundImage: `url(${backgroundImageNav})`, backgroundRepeat: "no-repeat", }}>
            <img className='absolute right-0 bottom-0' src={backgroundImageNav2} alt="Second Image" />
            <div>
                <div className="row">
                    <div className="col-sm-9">
                        <h1 className='text-white' style={{ fontSize: "40px" }}>Tipsy Tom is free!</h1>
                        <h4 className='text-white'>No credit card required. Your one-stop generative AI platform for legal work.</h4>
                    </div>
                    <div className="col-sm-3">
                        <button onClick={() => navigate("/app")} className='fw-bold text-primary bg-white hover:bg-gray-200 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-2xl transition duration-300 ease-in-out' style={{ fontSize: "25px" }}>Try for free</button>
                    </div>
                </div>
                <br /><br /><br />
            </div>
            <hr className='bg-white' />
            <div>
                <div className='row'>
                    <div className="col-sm">
                        <span className='d-flex'>
                            <svg width="142" height="27" viewBox="0 0 142 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M88.5961 0.220762C87.287 0.347448 86.9914 0.558594 86.9492 1.31871C86.9069 1.82546 86.8647 2.79673 86.8225 3.51462C86.7802 4.61257 86.6113 4.78149 85.3022 4.9504L83.8242 5.11932L85.3022 5.37269C86.9914 5.66829 86.8647 4.9504 86.9492 13.4384C86.9914 19.1393 87.0336 19.4349 88.0471 20.4062C88.8495 21.2508 89.4829 21.4619 91.2143 21.4619C93.0724 21.4619 93.5791 21.2508 94.6348 20.2373C95.3105 19.5616 95.8595 18.8015 95.8595 18.5481C95.8595 17.788 94.9727 18.0414 94.1703 19.0126C93.5369 19.815 93.2413 19.8994 92.6078 19.5194C91.9322 19.0971 91.8477 18.3792 91.8477 12.3405V5.62607L93.748 5.37269L95.6483 5.16155L93.748 4.9504L91.8477 4.78149L91.721 2.33221C91.6366 0.347448 91.4677 -0.0748405 90.8764 0.00961685C90.4964 0.0518475 89.4407 0.136305 88.5961 0.220762Z" fill="white" />
                                <path d="M22.5503 2.24766L23.6483 3.26116V10.7357V18.168L24.9574 17.9146C25.6753 17.7879 27.1955 17.6612 28.3357 17.6612C29.4759 17.6612 30.4049 17.4501 30.4049 17.2389C30.4049 16.9856 30.1515 16.8166 29.8137 16.8166C28.8424 16.8166 28.7158 15.8031 28.7158 9.00429C28.7158 2.79664 28.758 2.54327 29.687 1.90983C30.5738 1.2764 30.3205 1.23417 26.0131 1.23417L21.4102 1.19194L22.5503 2.24766Z" fill="white" />
                                <path d="M0 4.52669C0 8.83404 0.675663 9.63639 1.6047 6.51145C2.40705 3.89326 3.63169 2.03519 4.94078 1.52844C7.22115 0.683861 7.22115 0.683861 7.09446 11.4945C6.96777 22.1784 6.92554 22.2206 4.77187 22.2628C4.22289 22.2628 3.8006 22.4318 3.8006 22.6851C3.8006 22.9385 6.03874 23.1074 9.71265 23.1074C13.3866 23.1074 15.6247 22.9385 15.6247 22.6851C15.6247 22.4318 15.2024 22.2628 14.6534 22.2628C12.7531 22.2628 12.6687 21.8406 12.6687 11.1566V1.06392L13.8511 1.35952C14.4845 1.48621 15.3291 1.95073 15.7936 2.33079C16.6382 3.13314 18.1584 5.87802 18.1584 6.7226C18.1584 7.0182 18.3696 7.56718 18.5807 7.90501C19.3408 9.04519 19.8476 7.52495 19.8476 3.97772V0.726089H9.9238H0V4.52669Z" fill="white" />
                                <path d="M32.0938 4.56996C32.0938 4.7811 32.3894 4.99224 32.7272 4.99224C33.9941 4.99224 34.2052 6.63917 34.2052 15.7184C34.2052 24.3753 34.163 24.8398 33.3606 25.3466C32.8961 25.6422 32.516 25.98 32.516 26.1067C32.516 26.2756 41.0463 26.2756 41.8064 26.1067C41.9331 26.1067 41.3841 25.8111 40.6662 25.4733C39.3571 24.9243 39.2727 24.7554 39.2727 22.7284V20.617H42.102C44.3401 20.617 45.2269 20.4058 46.2827 19.6879C50.8012 16.6052 50.9701 6.97701 46.5783 4.61218C45.2692 3.93652 41.9753 4.02098 40.5395 4.7811C39.3994 5.3723 39.2727 5.3723 39.2727 4.7811C39.2727 4.27435 38.6392 4.14767 35.6832 4.14767C33.5718 4.14767 32.0938 4.31658 32.0938 4.56996ZM43.7067 6.93478C44.2979 8.15941 44.3824 9.34183 44.2557 12.8468C44.0868 18.0832 43.3266 19.5612 40.9196 19.5612H39.4838L39.3571 13.3536C39.2727 7.56821 39.3149 7.06146 40.1172 6.25911C41.4686 4.90779 42.8621 5.16116 43.7067 6.93478Z" fill="white" />
                                <path d="M54.8969 4.44402C53.25 5.03523 52.3632 6.38655 52.3632 8.32908C52.3632 10.2294 52.4899 10.3561 55.7415 13.4388C58.5286 16.057 59.1198 16.8171 59.1198 17.915C59.1198 19.3931 58.4442 20.1954 57.2618 20.1954C55.9104 20.1532 54.6436 18.8863 53.8412 16.6904C52.9122 14.1989 52.3632 13.8188 52.321 15.6347C52.2787 16.3948 52.2365 17.7461 52.1521 18.5907C52.0254 20.2376 52.6166 21.1244 53.2078 20.1954C53.4612 19.7731 53.8412 19.7731 54.8969 20.1532C56.8817 20.8288 60.6401 20.7444 62.0759 19.9843C63.4694 19.2664 64.6096 17.4083 64.6096 15.8458C64.6096 14.4523 63.385 13.0165 59.9222 10.2294C57.5574 8.32908 57.0084 7.69565 57.0084 6.72438C57.0084 5.11968 57.8952 4.44402 59.5844 4.78185C60.6401 5.03523 61.0624 5.45751 61.8225 7.14667C63.0471 10.0182 63.765 9.84932 63.765 6.68215C63.765 4.73962 63.6383 4.14842 63.1738 4.14842C62.836 4.14842 62.4559 4.31733 62.287 4.57071C62.1181 4.82408 61.6536 4.82408 60.9779 4.57071C59.8377 4.10619 55.9527 4.06396 54.8969 4.44402Z" fill="white" />
                                <path d="M65.7508 5.077C66.2153 5.62598 68.1579 9.5955 70.016 13.9873C73.0564 20.9128 73.3943 22.053 73.0142 23.0665C72.5075 24.4179 71.4095 25.6847 70.7761 25.6847C70.5227 25.6847 70.5649 25.4736 70.8605 25.178C71.6206 24.4179 71.4517 22.8132 70.5649 22.3064C69.5937 21.7997 67.5667 21.7574 66.891 22.2219C66.6376 22.3909 66.2998 22.9398 66.1309 23.4466C65.6664 24.9668 67.4822 26.5293 69.7626 26.5293C73.1409 26.5293 73.2253 26.4026 77.8705 12.8471C79.1796 9.08875 80.5732 5.71043 81.1222 5.16146L82.0512 4.14796L78.9263 4.19019C76.3503 4.19019 75.9702 4.27465 76.7726 4.65471C77.8705 5.20369 78.335 6.59724 77.9972 8.49754C77.7016 10.3134 75.9702 14.8319 75.7169 14.5363C75.2946 14.114 72.5497 7.31513 72.3385 6.17495C72.1696 5.24592 72.2963 4.90808 73.0987 4.61248C73.8166 4.31688 72.8453 4.19019 69.5092 4.19019L64.9062 4.14796L65.7508 5.077Z" fill="white" />
                                <path d="M99.6595 5.796C97.6325 6.85172 96.6612 8.75202 96.3656 11.9614C96.0278 16.6488 97.5058 19.816 100.631 20.9984C102.742 21.8008 106.881 21.5474 108.696 20.4917C111.272 19.0559 112.117 17.2823 112.117 13.2283C112.117 9.34323 111.357 7.52739 108.95 5.96492C107.092 4.65582 101.898 4.57136 99.6595 5.796ZM105.994 7.18955C106.627 8.58311 106.838 9.97666 106.838 13.1438C106.838 17.409 106.332 19.4782 104.98 20.5761C104.347 21.1251 104.136 21.0406 103.122 20.0271C101.982 18.9292 101.982 18.8447 101.982 13.3127C101.982 8.66757 102.109 7.52739 102.742 6.55612C103.84 4.82473 105.023 5.07811 105.994 7.18955Z" fill="white" />
                                <path d="M114.905 5.79498C115.792 6.51287 115.876 6.93516 115.961 11.5803C116.088 18.1681 115.834 20.2373 114.821 20.4906C112.878 21.0396 114.356 21.4619 118.03 21.4619C120.226 21.4619 122.042 21.3352 122.042 21.1241C122.042 20.9552 121.746 20.4484 121.408 20.0261C120.522 18.8859 120.522 10.1868 121.366 8.1598C122 6.63956 123.815 5.75275 124.66 6.59733C124.913 6.85071 125.082 9.63781 125.082 13.4806C125.082 18.8859 124.956 20.0261 124.407 20.4484C124.027 20.7018 123.731 21.0396 123.731 21.2085C123.731 21.3352 125.547 21.4619 127.743 21.4619C131.417 21.4619 132.726 21.0819 131.121 20.4484C130.614 20.2795 130.488 19.1816 130.488 14.3675C130.488 9.04661 130.572 8.37095 131.374 7.35745C131.839 6.76625 132.557 6.2595 132.979 6.2595C134.499 6.2595 134.711 7.10408 134.711 13.734C134.711 19.0971 134.584 20.2795 134.077 20.4484C132.472 21.0819 133.781 21.4619 137.455 21.4619C141.509 21.4619 142.143 21.2508 140.665 20.3217C139.905 19.8572 139.82 19.1816 139.694 13.2695C139.525 5.20378 139.398 4.99263 135.133 4.99263C132.895 4.99263 132.05 5.16155 131.163 5.87944L130.023 6.76625L129.052 5.87944C127.869 4.78149 123.604 4.61257 122.126 5.6683C121.282 6.2595 121.155 6.2595 120.944 5.6683C120.733 5.11932 119.973 4.99263 117.312 4.99263C113.934 4.99263 113.934 4.99263 114.905 5.79498Z" fill="white" />
                                <path d="M23.733 21.5472C22.8884 22.3917 23.1418 24.292 24.1553 25.0099C27.0269 27.0369 31.292 23.9964 28.8005 21.7583C27.8715 20.9137 24.4931 20.787 23.733 21.5472Z" fill="white" />
                            </svg>
                            <span className='ml-2 mr-5 text-white' style={{ opacity: "0.5" }}>©2023 All rights @Lipi Labs. Doing AI right.</span>
                        </span>
                    </div>
                    <div className="col-sm d-flex justify-content-around">
                        <span onClick={() => navigate("/app/contact-us", { state: { data: location.pathname } })} className='cursor-pointer text-white'>
                            <img width="20" height="20" src="https://img.icons8.com/fluency/20/mail--v1.png" alt="mail--v1" />
                            <span className='position-relative -top-5 left-6'>
                                Contact Us : talk@tipsytom.com
                            </span>
                        </span>
                        <span className='d-flex justify-content-center -mt-2'>
                            <Link style={{ color: "#000000" }} className="nav-link text-white" to="/privacy-policy">
                                <span className='underline-nav'>Privacy Policy</span>
                            </Link>
                            <Link style={{ color: "#000000" }} className="nav-link text-white" to="/terms-of-service">
                                <span className='underline-nav'>Terms of service</span>
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterLanding
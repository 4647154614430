import Keycloak from "keycloak-js";

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
  document.getElementById("root").innerHTML = `<div style="margin-top:45vh" class="text-center"><div class="spinner-border mt-4 text" role="status"></div></div>`
  _kc.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
        sessionStorage.removeItem("error")
      }
      onAuthenticatedCallback(); //render
    })
    .catch(error => {
      onAuthenticatedCallback() //app2
      sessionStorage.setItem("error", "Server Down")
      console.log(error)
    })
};
const doLogin = _kc.login;
const doSignUp = _kc.register;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getFullName = () => _kc.tokenParsed?.name;
const getEmail = () => _kc.tokenParsed?.email;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getUserId = () => _kc.tokenParsed?.sub;

const UserService = {
  initKeycloak,
  doLogin,
  doSignUp,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getFullName,
  getEmail,
  getUserId,
};

export default UserService;

export const formdata = [
    {
        name: "Claim Constructor:",
        placeholder: "Add instructions for how you want the first claim drafted. If referring to elements in a figure, link the figures by adding them below.",
        id: "claims_invention",
        type: "textarea",
        row: 2,
        element: "input-text",
        required: true,
        optional: false,
    },
    {
        name: "Type Of Claim:",
        placeholder: "",
        id: "claims_invention",
        type: "text",
        element: "input-text",
        required: false,
        optional: false,
    },
    {
        name: "Dependant Of Claim:",
        placeholder: "",
        id: "claims_invention",
        type: "text",
        element: "input-text",
        required: false,
        optional: false,
    },
    {
        name: "Add Reference to Figure?",
        element: "input-table-custom-drawing-select",
        id: "table2",
        required: false,
        table: [
            {
                claims: "",
                type_of_claim: "",
                dependent_on_claim: "",
                visibility: false,
                showOther: false,
                table: [
                    {
                        figure_no: "",
                        component_no: "",
                    },
                ]
            },
        ],
        required: false,
        optional: false,
    },

]



export const fieldNames = [
    "claim",
];


export const storageName = "masterClaimsAdvance"


export const requiredCheck = [{
    field: "claims",
    Message: "Please fill the Description of Claims:"
},]

export const agreementType = "Claims Generator – Advanced"

export const api = "/api/claims_advanced"
export const apiRedirection = "/app/claims"
export const downloadDocName = "Claims_"

export const nextTo = "/app/title-and-technical-field"
export const previousTo = "/app/dfd"
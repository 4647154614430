import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import NoteForUser from './NoteForUser';
import FooterOthers from './FooterOthers';
const NewClaims = () => {
    const navigate = useNavigate()

    const [note, setNote] = useState(false)
    return (
        <section className='container-fluid'>
            <div className={"row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                <div className='col-sm'>
                    {/* <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span> */}
                    <h4 className='mx-5 text-primary'>{"Claims Playground"}</h4>
                </div>
                <div className='col-sm items-center float-end'>
                    <button onClick={() => {
                        setNote(true)
                    }} className='btn btn-outline-primary float-end mx-2'>Skip</button>
                    <Link
                        to="/app"
                        // spiketip-title="Go back to home page" spiketip-pos="bottom"

                        className={`btn btn-outline-primary float-end mx-2`}
                    >
                        &#8592; Home
                    </Link>
                </div>
            </div>
            <div className="flex justify-center items-center mt-14">
                <div className="flex space-x-6">
                    {/* Simple Claim Card */}
                    <div onClick={() => navigate("/app/simple-claim")} className="relative w-96 h-96 bg-[#cfead9] hadow-xl rounded-lg overflow-hidden transform hover:scale-105 hover:-rotate-2 hover:bg-blue-600 hover:text-white transition duration-300 cursor-pointer">
                        <div className="absolute inset-0 flex flex-col justify-center items-center p-8">
                            <h3 className="text-3xl font-bold mb-6 text-center">Simple</h3>
                            <p className="text-lg text-center">Use Simple if you are starting with very little context and want to iterate
over the possibilities. Simple is ideal for initial brainstorming, discovering
possibilities and iterating over whole sets of claims.</p>
                            <div className="mt-8">
                                <button onClick={() => navigate("/app/simple-claim")} className="flex bg-white text-blue-800 px-6 py-3 rounded-lg font-semibold hover:bg-blue-800 hover:text-white transition duration-300 btn-light">Go
                                    <svg className='mt-2 ml-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="black" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    {note && <NoteForUser detail={true} note={"Drafting the claims first is highly recommended. Are you sure you want to skip?"} onConfirm={() => {
                        navigate("/app/title-and-technical-field")
                        setNote(false)
                    }} onCancle={() => setNote(false)} />}

                    {/* Manual-Advance Claim Card */}
                    <div onClick={() => navigate("/app/advance-claim")} className="relative w-96 h-96 bg-[#cfead9] shadow-xl rounded-lg overflow-hidden transform hover:scale-105 hover:-rotate-2 hover:bg-blue-600 hover:text-white transition duration-300">
                        <div className="absolute inset-0 flex flex-col justify-center items-center p-8">
                            <h3 className="text-3xl font-bold mb-6 text-center">Advanced</h3>
                            <p className="text-lg text-center">Use Advanced if you have a fair idea of how you want the claims to be
structured. Advanced is ideal for experienced professionals who are drafting
claims one-by-one, with a combination of manual and AI powered workflows.</p>
                            <div className="mt-8">
                                <button onClick={() => navigate("/app/advance-claim")} className="flex bg-white text-blue-800 px-6 py-3 rounded-lg font-semibold hover:bg-blue-800 hover:text-white transition duration-300 btn-light">Go
                                    <svg className='mt-2 ml-3' width="32" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32 6L22 0.226499L22 11.7735L32 6ZM-8.74228e-08 7L23 7L23 5L8.74228e-08 5L-8.74228e-08 7Z" fill="black" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
                <FooterOthers />
        </section>
    );
};

export default NewClaims;

export const formdata = [
    {
        name: "Description of Embodiments",
        element: "section-heading"
    },
    {
        name: "Information for Embodiment :",
        element: "input-table-custom-drawing-select",
        id: "table2",
        required: true,
        table: [
            {
                claims: "",
                visibility: false,
                table: [
                    {
                        figure_no: "",
                        component_no: "",
                    },
                ]
            },
        ],
        required: false,
        optional: false,
    },
    {
        name: "Limitations of Prior Art",
        element: "section-heading"
    },
    {
        name: "Limitations/disadvantages of prior art",
        id: "pa_limitations",
        type: "text",
        element: "input-text",
        required: true,
        optional: false,
    },

]
export const fieldNames = [
    "pa_limitations",
];


export const storageName = "masterDrawingDescription"


export const requiredCheck = [{
    field: "pa_limitations",
    Message: "Please fill the Limitations/disadvantages of prior art"
}]

export const agreementType = "Drawings And Detailed Description Of Preferred Embodiments"

export const api = "/api/drawings_and_ddpe"
export const apiRedirection = "/app/drawings-and-ddpe"
export const downloadDocName = "Drawings_and_DDPE_"

export const previousTo = "/app/abstract"
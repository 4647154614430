import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import img1 from "../../assets/carousel1.jpg"
import img2 from "../../assets/carousel2.jpg"
import img3 from "../../assets/carousel3.jpg"


const MyCarousel = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        // setTimeout(() => {
        //     document.querySelectorAll(".visually-hidden").map((ele) => {
        //         ele.classList.add("btn-dark")
        //     })
        // }, 200)
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex === 2 ? 0 : prevIndex + 1)); // Assuming you have 3 slides
        }, 6000); // Change slide every 6 seconds

        return () => clearInterval(interval);
    }, []);
    const listStyle = {
        listStyleType: 'disc',
        padding: '0', // Set padding to 0
    };
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <div className="row">
                    <div className="col-sm">
                        <img
                            className="d-block w-100 h-100"
                            src={img1}
                            alt="First slide"
                        />
                    </div>
                    <div className="col-sm py-3">
                        <h4>Multiply the productivity of your patents drafting by 4X</h4>
                        <div className="col-sm p-3">
                            <ol style={{ listStyleType: 'disc' }}>
                                <li style={listStyle}>Streamlined drafting workflow for teams</li>
                                <li>Safe and secure enterprise grade solution</li>
                                <li>Pioneering and cutting-edge AI powered software</li>
                                {/* <li> Button - Request a Demo</li> */}
                            </ol>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row">
                    <div className="col-sm">
                        <img
                            className="d-block w-100"
                            src={img2}
                            alt="First slide"
                        />
                    </div>
                    <div className="col-sm py-3">
                        <h4>Get high-quality customised patent drafts in no time</h4>
                        <div className='p-3'>
                            <ol style={{ listStyleType: 'disc', padding: '0' }}>
                                <li className='pb-0'>Write high-quality patents with our step-by-step scripter based approach</li>
                                <li className='pb-0'>Intelligent and measured use of AI to produce excellent first drafts</li>
                                <li className='pb-0'>Configured to respect your style and and customisation needs</li>
                                <li className='pb-0'>Quick to deploy and ready-for-use</li>
                                {/* <li> Button - Request a Demo</li> */}
                            </ol>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row">
                    <div className="col-sm">
                        <img
                            className="d-block w-100"
                            src={img3}
                            alt="First slide"
                        />
                    </div>
                    <div className="col-sm py-3">
                        <h4>Assured confidentiality</h4>
                        <div className='p-3'>
                            <ol style={{ listStyleType: 'disc' }}>
                                <li className='py-0'>High level of importance is placed on security and confidentiality.</li>
                                <li className='py-0'>Any data uploaded to or generated from our product is never utilized for training AI models.</li>
                                <li className='py-0'>All information is encrypted, ensuring that neither we nor any third party can access your data.</li>
                                {/* <li> Button - Request a Demo</li> */}
                            </ol>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
};

export default MyCarousel;

export const formdata = [
    {
        name: "Title Editor ",
        // placeholder: "Provide relevant description of the invention for generating the title",
        placeholder: "Automatically pre-filled when claims have been saved. If not, type a suitable title.",
        id: "invention_title",
        type: "textarea",
        element: "input-textarea",
        required: true,
        optional: false,
    },
    {
        name: "Field of the Invention (Select one or more fields) ",
        id: "invention_field",
        element: "text",
        required: true,
        optional: false,
    },
    {
        name: "Mechanical engineering",
        id: "Mechanical",
        type: "checkbox",
        nameAtt: "Mechanical engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Civil engineering",
        id: "Civil",
        type: "checkbox",
        nameAtt: "Civil engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Computer engineering",
        id: "Computer",
        type: "checkbox",
        nameAtt: "Computer engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Communication engineering",
        id: "Communication",
        type: "checkbox",
        nameAtt: "Communication engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Electrical engineering",
        id: "Electrical",
        type: "checkbox",
        nameAtt: "Electrical engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Life Science engineering",
        id: "LifeScience",
        type: "checkbox",
        nameAtt: "Life Science engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Textile engineering",
        id: "Textile",
        type: "checkbox",
        nameAtt: "Textile engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Chemical engineering",
        id: "Chemical",
        type: "checkbox",
        nameAtt: "Chemical engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Automobile engineering",
        id: "Automobile",
        type: "checkbox",
        nameAtt: "Automobile engineering",
        element: "input-checkbox-with-yes-no",
        required: false,
        optional: false,
    },
    {
        name: "Others",
        id: "other",
        type: "text",
        element: "input-checkbox-with-yes-no-text",
        required: false,
        optional: false,
    },


]



export const fieldNames = [
    "invention_title",
    "other"
];

export const storageName = "masterTechnicalField"

export const requiredCheck = [{
    field: "invention_title",
    Message: "Please fill the Title Generator"
}]

export const agreementType = "Title and Technical Field"

export const api = "/api/technical_field"
export const apiRedirection = "/app/title-and-technical-field"
export const downloadDocName = "Title_and_Technical_Field_"

export const nextTo = "/app/background"


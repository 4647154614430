import UserService from "../../services/UserService";

export const injectDataToProgressStorage = (script) => {
    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const time = `${hours}:${minutes}`;
        return time;
    };

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const date = `${day}/${month}/${year}`;
        return date;
    };
    const masterProgressData = JSON.parse(localStorage.getItem(UserService.getUserId() + "Progress"));
    let myArray = []
    if (masterProgressData) {
        myArray = masterProgressData
        // loop
        myArray.forEach((ele, ind) => {
            if (ele.name === script) {
                ele.lastTime = getCurrentTime()
                ele.lastDate = getCurrentDate()
                ele.done = true
                myArray[ind] = ele
            }
        })
    }
    localStorage.setItem(UserService.getUserId() + "Progress", JSON.stringify([...myArray]))
}
export const formdata = [
    {
        name: "Description of the Invention (Refer to IDF)",
        id: "description_invention",
        type: "text",
        element: "input-text",
        required: true,
        optional: false,
    },
    {
        name: "Any other information",
        placeholder: "",
        id: "info_embodiments",
        type: "text",
        element: "input-text",
        required: false,
        optional: false,
    },

]
export const fieldNames = [
    "description_invention",
    "info_embodiments"
];


export const storageName = "masterEmbodiments"


export const requiredCheck = [{
    field: "claims_invention",
    Message: "Please fill the Description of the Invention (Refer to IDF)"
},]

export const agreementType = "Embodiments Description"

export const api = "/api/<whatever>"
export const apiRedirection = "/app/embodiments-description"
export const downloadDocName = "Embodiments_Description_"
export const formdata = [
    {
        name: "Draft of Claims",
        placeholder: "Insert draft of the claims or generate claims using the Claims Scripter and you will see it here.",
        id: "claims_draft",
        type: "textarea",
        element: "input-text",
        required: true,
        optional: false,
    },
]



export const fieldNames = [
    "claims_draft",
];


export const storageName = "masterSummary"


export const requiredCheck = [{
    field: "claims_draft",
    Message: "Please fill the Draft of Claims"
}]

export const agreementType = "Summary"

export const api = "/api/summary"
export const apiRedirection = "/app/summary"
export const downloadDocName = "Summary_"


export const nextTo = "/app/abstract"
export const previousTo = "/app/background"
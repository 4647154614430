import React, { useState, useEffect } from 'react'
import UserService from "../../services/UserService";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import Popup from "./Popup";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import FooterOthers from './FooterOthers';
import {
    formdata,
    fieldNames,
    storageName,
    requiredCheck,
    agreementType,
    api,
    apiRedirection,
    downloadDocName,
    nextTo
} from "../Agreement_Data/TechnicalFieldAndObject.js";
import { injectDataToProgressStorage } from "../Agreement_Data/Utils.js"
import NoteForUser from './NoteForUser.jsx';

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

const TechnicalFieldAndObject = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    // const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState("");
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState([])
    const [descriptionValue, setDescriptionValue] = useState(false);


    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("Save as..");
    const [tagStore, setTagStore] = useState([]);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: ""
    })


    const [divHeight, setDivHeight] = useState(0);
    const [ID, setID] = useState("")
    const [note, setNote] = useState(true)
    const [note2, setNote2] = useState(false)

    useEffect(() => {
        const firstDiv = document.querySelector('.firstDiv');
        firstDiv && setDivHeight(firstDiv.offsetHeight - 134);
    }, []);

    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage()
        resetButtonView()
        if (UserService.isLoggedIn()) getDataFromLibrary()
        getLeftHandFirstField()
        getTheRightHand()
    }, []);


    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener('input', handleKeyPress);
        document.addEventListener('click', handleKeyPress);

        return () => {
            document.removeEventListener('input', handleKeyPress);
            document.removeEventListener('click', handleKeyPress);
        };
    }, [tagStore]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const getLeftHandFirstField = () => {
        setTimeout(() => {
            const leftHand = JSON.parse(localStorage.getItem(UserService.getUserId() + "titleGenerationOutput"))
            if (leftHand) {
                document.getElementById("invention_title").value = leftHand
            }
        }, 200)
        // localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))

    }

    const getTheRightHand = () => {
        // localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))
        const rightHand = JSON.parse(localStorage.getItem(UserService.getUserId() + "TechnicalFieldOutput"))
        if (rightHand) {
            setCreatAgreement(rightHand)
            setHandleSubmit(true);
        }
        const newID = JSON.parse(localStorage.getItem(UserService.getUserId() + "TechnicalFieldID"))
        if (newID) setID(newID)

    }

    const editFile = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("id", ID);
                sendData.append("edited_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/edit_file`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                // setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))
                    dispatch(
                        getNotification({
                            message: "The file has been successfully edited.",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                // setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            // setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }
        // 
    };


    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem(UserService.getUserId() + storageName))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString)
            setTagStore(masterStorageObject.tags)
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || '';
                }
            });
        }
    }
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value

        const masterStorageObject = {}

        fieldNames && fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            const value = field ? field.value : "";
            masterStorageObject[fieldName] = value;
        });

        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem(UserService.getUserId() + storageName, JSON.stringify(masterStorageObject))
    }


    const getDataFromLibrary = () => {
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data;
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name);
            if (
                outputJSONfromLibrary.file_tags &&
                outputJSONfromLibrary.file_tags != null &&
                outputJSONfromLibrary.file_tags != "null"
            )
                setTagStore(outputJSONfromLibrary.file_tags.split(";"));

            fieldNames &&
                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    if (field) {
                        field.value =
                            outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
                    } else {
                        return "";
                    }
                });
        }
    }
    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end")
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end")
            })
        }
    }


    const getPlanDetails = async (value) => {
        if (UserService.isLoggedIn()) {
            try {
                if (!value === "noRefresh") setLoaderCredit(true); // wanna keep the form datas so using this
                const headers = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "access_token": UserService.getToken()
                };
                const getResponse = await axios.post(
                    `${REACT_APP_CREATE_AGREEMENT_API}/get_user_status?keycloak_user_id=${UserService.getUserId()}`,
                    null,
                    { headers: headers }
                );
                if (getResponse.status === 200) {
                    dispatch(getUserDetails(getResponse.data));
                    if (Object.entries(getResponse.data.user_subscription_summary)[0][1].plan.active) {
                        setDescriptionValue(true);
                    }
                    // const download = getResponse.data.user_credit_summary.num_remaining_downloads;
                    // dispatch(
                    //     getNotification({
                    //         message: `You have ${download} ${download === 1 || download === 0 ? "credit" : "credits"
                    //             }`,
                    //         type: "info",
                    //     })
                    // );
                }
            } catch (err) {
                // console.log("Error", err);
                if (err.response && err.response.status === 400) {
                    dispatch(getUserDetails(err.response.data.detail));
                    // dispatch(
                    //   getNotification({
                    //     message:
                    //       "You have run out of credits!! Please subscribe and get more credits.",
                    //     type: "info",
                    //   })
                    // );
                } else if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderCredit(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }
    };

    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Optionally, you can add smooth scrolling animation
            });
        }
    };
    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader()
        }, 100)

        let interval;
        getJoke()
        interval = setInterval(getJoke, 10000);
        let isFieldEmpty = false;
        requiredCheck.forEach((ele) => {
            const fieldValue = document.getElementById(ele.field).value;
            if (!fieldValue) {
                dispatch(
                    getNotification({
                        message: ele.Message,
                        type: "default",
                    })
                );
            }
            if (fieldValue) isFieldEmpty = true;
        });

        //radiobox check
        if (checkboxValues.length === 0 && !document.getElementById("other").value)
            dispatch(
                getNotification({
                    message: "Please select any one or fill the Others field",
                    type: "default",
                })
            );

        //radiobox check

        else if (isFieldEmpty && UserService.isLoggedIn()) {
            try {
                setLoaderAgreement(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("agreement_type", agreementType);
                sendData.append("file_name", renameString);
                sendData.append("file_tags", tagStore.join(",").replaceAll(",", ";").replace(/;$/, ''));

                sendData.append("session_id", "");


                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    const value = field ? field.value : "";
                    sendData.append(fieldName, value);
                });
                sendData.append("invention_field", checkboxValues.join(","));


                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API + api}`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setHandleSubmit(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    clearInterval(interval)
                    const text = getResponse.data.agreement_text;
                    setCreatAgreement(text);
                    setID(getResponse.data.id)
                    localStorage.setItem(UserService.getUserId() + "TechnicalFieldID", JSON.stringify(getResponse.data.id))
                    localStorage.setItem(UserService.getUserId() + "TechnicalFieldOutput", JSON.stringify(text))
                    // getPlanDetails("noRefresh");
                    // console.log("Success");
                    injectDataToProgressStorage(agreementType)
                }
            } catch (err) {
                console.log('error', err);
                clearInterval(interval)
                setHandleSubmit(false);
                setLoaderAgreement(false)
                // console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderAgreement(false);
        }
        else if (!UserService.isLoggedIn()) {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
                });
            }, 3000);
        }
        setLoaderAgreement(false);


    };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            setCreatAgreement(text)
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${downloadDocName + UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }

    };


    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = '';
            }
        });
        document.querySelectorAll(("input[type=checkbox]")).forEach((ele) => ele.checked = false)
        setRenameString("New File")
        let temp = []
        setTagStore(temp)
    };

    // const getCurrentMonthYear = () => {
    //   const date = new Date();
    //   const month = date.toLocaleString("default", { month: "long" });
    //   const year = date.getFullYear();
    //   return `${month} ${year}`;
    // };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input")
        let tempArr = [...tagStore]
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        }
        else {
            tempArr.push(tag.value)
        }
        setTagStore(tempArr);
        tag.value = ""
    }

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore]
        if (value) tempArr = tempArr.filter(e => e !== value)
        setTagStore(tempArr);
    }

    const getJoke = async () => {
        try {

            const response = await axios.get("https://official-joke-api.appspot.com/random_joke")
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline
            })
        }
        catch (err) {
            console.log("error", err);
        }
    }
    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="xl:grid xl:grid-cols-4 xl:gap-6" style={{ marginBottom: "30px" }}>
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5 firstDiv">
                                    <div className={handleSubmit ? "d-none" : "row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                        <div className='col-sm'>
                                            {/* <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span> */}
                                            <h4 className='ml-5 text-primary'>{agreementType}</h4>
                                        </div>
                                        <div className='col-sm items-center float-end'>
                                            <Link
                                                to="/app"
                                                spiketip-title="Go back to home page" spiketip-pos="bottom"

                                                className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                &#8592; Home
                                            </Link>
                                            <button
                                                // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    setNote2(true)
                                                }}
                                                className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-redo mr-2"></i>
                                                Reset Form
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                                    <div className="row menu-bar mt-1 container-fluid">
                                        <div className={handleSubmit ? "" : `col-sm px-2`}>
                                            <div className="row">
                                                <div className="col-sm-9">
                                                    <button className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"}`} >
                                                        <input
                                                            id="input-file-name"
                                                            className={rename ? "float-start w-4/6" : "d-none"}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") setRename(false);
                                                            }}
                                                            onChange={(e) => {
                                                                setRenameString(e.target.value);
                                                            }}
                                                            defaultValue={renameString}
                                                            type="text"
                                                            style={{
                                                                // width: "150px",
                                                                height: "30px",
                                                                color: "black",
                                                            }}
                                                        />
                                                        <span
                                                            onClick={() => {
                                                                if (rename) setRename(false);
                                                                else setRename(true);
                                                            }}
                                                            id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                            className={rename ? "d-none" : "float-start text-primary"}>
                                                            {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                                        </span>
                                                        <span className="ml-2 float-end">
                                                            |
                                                            <i
                                                                onClick={() => {
                                                                    if (rename) setRename(false);
                                                                    else setRename(true);
                                                                }}
                                                                className={
                                                                    rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                                }
                                                            >
                                                            </i></span>

                                                        {/* <i className="fas fa-times"></i> */}
                                                    </button>
                                                </div>
                                                <div className="col-sm-3">
                                                    <button
                                                        // spiketip-title="Reset Form" spiketip-pos="bottom"
                                                        onClick={() => {
                                                            setNote2(true)
                                                        }}
                                                        className={`btn btn-outline-dark float-end ${handleSubmit ? "" : "d-none"}`}
                                                    >
                                                        <i className="fas fa-redo mr-2"></i>
                                                        Reset Form
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        getTagButtonFromArray(e.target.value)
                                                    }
                                                }}
                                                placeholder="Add tag here"
                                                className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                                style={{
                                                    height: "35px",
                                                    // fontSize: handleSubmit ? "14px" : "",
                                                    width: "110px",
                                                    border: "2px solid #007BFF",
                                                    textAlign: "left",
                                                }}
                                                type="text"
                                            />
                                            <button
                                                spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                                onClick={() => getTagButtonFromArray()}
                                                id="add-tag"
                                                className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                            >
                                                <i className="fas fa-plus plus-position"></i>
                                            </button>

                                            <span className="scrollable-span mx-1 mt-1 pb-1 w-full sm:w-4/6">
                                                {tagStore.length > 0 &&

                                                    tagStore.map((ele, ind) => (
                                                        <button
                                                            style={{ borderRadius: "9999px" }}
                                                            key={ind}
                                                            className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                            onMouseEnter={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.remove("d-none")
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.add("d-none")
                                                            }}
                                                        >
                                                            {ele}
                                                            <i
                                                                // style={{ display: "none" }}
                                                                onClick={() => { removeTagFromArray(ele) }}
                                                                className="fas fa-window-close ml-1 d-none"></i>
                                                        </button>
                                                    ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white px-3 my-4">
                                        <div className="grid grid-cols-3 gap-1 px-2 mt-4">
                                            {formdata.map((e, i) => (
                                                <div key={i} className="col-span-6 sm:col-span-3 px-1">
                                                    {e.element === "text" &&
                                                        <label
                                                            htmlFor={e.id}
                                                            className="block text-sm font-medium text-gray-700 mt-2"
                                                        >
                                                            {e.name}
                                                            <span
                                                                className={`${e.required ? "text-danger" : "d-none"
                                                                    }`}
                                                            >
                                                                *
                                                            </span>
                                                        </label>}
                                                    {e.element && e.element === "input-textarea" &&
                                                        <div className='col-span-6 sm:col-span-3'>
                                                            <label
                                                                htmlFor={e.id}
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                {e.name}
                                                                <span
                                                                    className={`${e.required ? "text-danger" : "d-none"
                                                                        }`}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <textarea
                                                                type={e.type ? e.type : "text"}
                                                                placeholder={e.placeholder}
                                                                name={e.id}
                                                                id={e.id}

                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            >
                                                            </textarea>
                                                        </div>
                                                    }
                                                    {e.element === "input-checkbox-with-yes-no" &&
                                                        <div
                                                            className={`col-span-6 sm:col-span-3 flex ${e.type === "radio" ||
                                                                e.type === "checkbox"
                                                                ? "items-center"
                                                                : ""
                                                                } px-2 my-2`}
                                                        >
                                                            <label
                                                                htmlFor={e.id}
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                {e.name}
                                                                <span
                                                                    className={`${e.required ? "text-danger" : "d-none"
                                                                        }`}
                                                                >
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type={
                                                                    e.type ? e.type : "text"
                                                                }
                                                                placeholder={e.placeholder}
                                                                name={e.nameAtt}
                                                                // id={e.id + "Yes"}

                                                                className={`${e.type === "radio" ||
                                                                    e.type === "checkbox"
                                                                    ? "mb-1.5 block shadow-sm sm:text-sm mx-2"
                                                                    : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"}`}
                                                                onClick={(element) => {
                                                                    if (element.target.checked === true)
                                                                        setCheckboxValues([...checkboxValues, e.name])
                                                                    else {
                                                                        const temp = checkboxValues.filter((ele) => ele !== e.name)
                                                                        setCheckboxValues(temp)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {e.element === "input-checkbox-with-yes-no-text" &&
                                                        <div className='px-2'>
                                                            <label
                                                                // htmlFor={e.id + "No"}
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                {e.name}
                                                            </label>
                                                            <input
                                                                type={e.type ? e.type : "text"}
                                                                placeholder={e.placeholder}
                                                                name={e.id}
                                                                id={e.id}

                                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-span-6 sm:col-span-3 text-center mx-2 px-4 pb-3 mb-1">
                                        <Link
                                            to="/app"
                                            spiketip-title="Go back to home page" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-outline-dark mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm px-4"
                                            onClick={() => { navigate("/app") }}
                                        >
                                            Back
                                        </Link>
                                        <button
                                            spiketip-title="Submit Form" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                            onClick={() => { sendData() }}
                                        >
                                            Submit
                                        </button>
                                        {/* <button
                                            // spiketip-title="Submit Form" spiketip-pos="top"
                                            type="submit"
                                            className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            onClick={() => { navigate(nextTo) }}
                                        >
                                            Next Script
                                        </button> */}
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "10vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title="Please wait while the result is being generated."
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                    <br />
                                    <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                        Mark Twain
                                    </figcaption> */}
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar">
                                            <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                <div className='col-sm'>
                                                    <h5 className='ml-2 text-primary'>{agreementType}</h5>
                                                </div>
                                                <div className='col-sm text-end'>
                                                    <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                    <button
                                                        spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) {
                                                                setContentState(!contentState);

                                                                if (contentState) {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = true;
                                                                    document
                                                                        .getElementById("download")
                                                                        .setAttribute("disabled", true);
                                                                } else {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = false;
                                                                    document
                                                                        .getElementById("download")
                                                                        .removeAttribute("disabled");
                                                                    editFile(dispatch, REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI, apiRedirection, ID)
                                                                }
                                                            }
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/api/create_patent_scripter_1" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to edit the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i
                                                            id="icon"
                                                            className={
                                                                contentState ? "fas fa-edit" : "fas fa-save"
                                                            }
                                                        ></i>
                                                    </button>
                                                    <button
                                                        spiketip-title="Download Agreement" spiketip-pos="left"
                                                        id="download"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) download();
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/api/create_patent_scripter_1" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to download the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: `${divHeight}px`,
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement}
                                                <br />
                                            </div>
                                            <div className='text-center space-x-3'>
                                                <button
                                                    spiketip-title="Download Agreement" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/api/create_patent_scripter_1" })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button>
                                                <button
                                                    // spiketip-title="Submit Form" spiketip-pos="top"
                                                    type="submit"
                                                    className="btn btn-outline-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3"
                                                    onClick={() => { navigate(nextTo) }}
                                                >
                                                    Next Script
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* {note && <NoteForUser note={"Include elements that are relevant to the Claims and Description of Embodiments sections of your patent specifications. You don’t need to repeat the details that you include here, and you can recall them to be used consistently in Claims, and Description of Embodiments. If you miss something out or want to edit later, you can also add/ edit before their use in Scripters."} onConfirm={() => setNote(false)} />} */}
                        {note2 && <NoteForUser detail={true} note={"This will clear all fields in this Form. Are you sure you want to proceed?"} onConfirm={() => {
                            resetForm();
                            setHandleSubmit(false)
                            setNote2(false)
                        }} />}
                        <FooterOthers />
                    </div>
                </div>
            )
            }
        </div>
    );
}

export default TechnicalFieldAndObject
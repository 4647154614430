export const formdata = [
    {
        name: "Field in which the invention operates",
        placeholder: "To demonstrate that there is need for innovation, it is important to describe the current state of the field in which the proposed invention operates.",
        id: "result_scripter_1",
        type: "textArea",
        element: "input-text",
        required: true,
        optional: false,
    },
    {
        name: "Add Prior Art?",
        element: "checkbox-yes-no"
    },
    {
        name: "",
        element: "input-table",
        id: "",
        table: {
            "id": "table1",
            "name": "Table 1",
            "headers": [
                { "label": "Sl. No." },
                { "label": "Abstract" },
                { "label": "Application/ Patent No." },
            ],
        },
        required: false,
        optional: false,
    },
    {
        name: "Limitations and Drawbacks of Prior Art",
        placeholder: "",
        id: "pa_limitations",
        type: "textArea",
        row: 4,
        element: "input-text",
        required: true,
        optional: false,
    },
]

export const fieldNames = [
    "result_scripter_1",
    "pa_limitations",
    "info_background"
];

export const storageName = "masterBackground"


export const requiredCheck = [{
    field: "pa_limitations",
    Message: "Limitations and Drawbacks of Prior Art"
},
{
    field: "result_scripter_1",
    Message: "Please fill the Technical field"
},
]

export const agreementType = "Background"

export const api = "/api/background"
export const apiRedirection = "/app/background"
export const downloadDocName = "Background_and_objects_"
export const nextTo = "/app/summary"
export const previousTo = "/app/title-and-technical-field"
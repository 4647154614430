export const tabItem = [
    {
        name: "Figures and Drawings",
        description1:
            "If your patent specifications contain figures and drawings, use this Scripter to add the relevant information.",
        to: "/app/dfd",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Claims Playground",
        description1:
            "The most important section. Choose between Simple and Advanced Scripters to draft the Claims.",
        to: "/app/claim-selection",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Title and Technical Field",
        description1:
            "Generate the Title and Technical field. Pre-filled title if Claims Scripter already used.",
        description2: " ",
        buttonName: "Script",
        to: "/app/title-and-technical-field",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Background",
        description1:
            "Generate the Background section of the Patent Specifications.",
        description2: " ",
        buttonName: "Script",
        to: "/app/background",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Summary",
        description1:
            "Generate the Summary.Use claims first to prefill.",
        to: "/app/summary",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Abstract",
        description1:
            "Generate the Abstract section of the Patent Specifications. Pre-filled if Claims Scripter already used.",
        to: "/app/abstract",
        disabled: true,
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    {
        name: "Drawings and Detailed Description of Embodiments",
        description1:
            "Generate descriptions of drawings and embodiments.",
        disabled: true,
        to: "/app/drawings-and-ddpe",
        margin1: "10px",
        margin2: "15px",
        margin3: "9px",
        indiaflag: true,
        recentlyAdded: true
    },
    // {
    //     name: "Embodiments Description",
    //     description1:
    //         "A Scripter to generate the Detailed Description of Preferred Embodiments of the Patent Specifications. Follows: Description of Drawings.",
    //     to: "/app/embodiments-description",
    //     disabled: true,
    //     margin1: "10px",
    //     margin2: "15px",
    //     margin3: "9px",
    //     indiaflag: true,
    //     recentlyAdded: true
    // },
];
import React, { useEffect, useState } from "react";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, } from "../../Redux/Action/action.js";
import Loader from "react-js-loader";
import FooterOthers from './FooterOthers.jsx';

import {
    formdata,
    fieldNames,
    storageName,
    requiredCheck,
    agreementType,
    api,
    apiRedirection,
    downloadDocName,
    previousTo,
} from "../Agreement_Data/DDPEPart1.js";
import UserService from "../../services/UserService.js";
import { injectDataToProgressStorage } from "../Agreement_Data/Utils.js";
import NoteForUser from "./NoteForUser.jsx";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

function DescriptionofFiguresandDrawings() {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [checkBoxString, setCheckBoxString] = useState([]);
    // const [clause, setClause] = useState(true);
    const [createAgreement, setCreatAgreement] = useState([]);
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("Save as..");
    const [tagStore, setTagStore] = useState([]);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: "",
    });

    const [contentViolationPromt, setContentViolationPromt] = useState(false)


    const [divHeight, setDivHeight] = useState(0);

    const [table1, setTable1] = useState([
        ...formdata[1].table
    ])


    const [figure, setFigure] = useState([])
    const [component, setComponent] = useState([])

    const [ID, setID] = useState("")
    const [note, setNote] = useState(true)
    const [note2, setNote2] = useState(false)
    const [note3, setNote3] = useState(false)
    const [note4, setNote4] = useState(false)
    const [note5, setNote5] = useState(false)
    const [note6, setNote6] = useState(false)

    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage();
        resetButtonView();
        if (UserService.isLoggedIn()) {
            getTablesFromStorage()
            getDataFromLibrary()
        }
    }, []);
    useEffect(() => {
        //saving data to storage
        const temp = [...table1]
        localStorage.setItem(UserService.getUserId() + storageName + "Table1", JSON.stringify(temp));
        extractFigureComponenteNumbers()
    }, [table1]);
    useEffect(() => {
        window.scrollTo(0, 0);
        //default click
        setTimeout(() => {
            // document.getElementById("decider1").click()
            document.getElementById("decider3").click()
        }, 400);
    }, []);
    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener("input", handleKeyPress);
        document.addEventListener("click", handleKeyPress);

        return () => {
            document.removeEventListener("input", handleKeyPress);
            document.removeEventListener("click", handleKeyPress);
        };
    }, [tagStore]);

    // const updateData = () => {
    //     formdata[1].table = table1
    // }
    const onConfirm = () => setNote(false)

    const checkFigure = (value) => {
        switch (value) {
            case "case-1":
                setNote6(true)
                const newEle1 = document.querySelectorAll(".disable-case2");
                // Loop through the elements and disable them
                newEle1.forEach(element => {
                    element.disabled = false;
                });
                const div = document.querySelectorAll(".disable-div");
                div.forEach((ele) => {
                    // Find all form elements inside the div
                    const formElements = ele.querySelectorAll('input, textarea, select, button');
                    // Loop through the elements and disable them
                    formElements.forEach(element => {
                        element.disabled = true;
                        element.value = ""
                        // element.classList.add("text-[#000000]")
                    });
                })
                break;
            case "case-2":
                if (table1.length > 1) setNote5(true)
                else {
                    const newdiv = document.querySelectorAll(".disable-div");
                    newdiv.forEach((ele) => {
                        // Find all form elements inside the div
                        const formElements = ele.querySelectorAll('input, textarea, select, button');
                        // Loop through the elements and disable them
                        formElements.forEach(element => {
                            element.disabled = false;
                            // element.classList.add("text-[#000000]")
                        });
                    })
                    const newEle = document.querySelectorAll(".disable-case2");
                    // Loop through the elements and disable them
                    newEle.forEach(element => {
                        element.disabled = true;
                    });
                }
                break;
            case "case-3":
                const newEle2 = document.querySelectorAll(".disable-case2");
                // Loop through the elements and disable them
                newEle2.forEach(element => {
                    element.disabled = false;
                });
                const div3 = document.querySelectorAll(".disable-div");
                div3.forEach((ele) => {
                    // Find all form elements inside the div
                    const formElements = ele.querySelectorAll('input, textarea, select, button');
                    // Loop through the elements and disable them
                    formElements.forEach(element => {
                        element.disabled = false;
                        // element.classList.add("text-[#000000]")
                    });
                })
                break;
            default:
                const newEle3 = document.querySelectorAll(".disable-case2");
                // Loop through the elements and disable them
                newEle3.forEach(element => {
                    element.disabled = false;
                });
                const div4 = document.querySelectorAll(".disable-div");
                div4.forEach((ele) => {
                    // Find all form elements inside the div
                    const formElements = ele.querySelectorAll('input, textarea, select, button');
                    // Loop through the elements and disable them
                    formElements.forEach(element => {
                        element.disabled = false;
                        // element.classList.add("text-[#000000]")
                    });
                })
                break;
        }
    }

    const editFile = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("id", ID);
                sendData.append("edited_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/edit_file`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                // setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    dispatch(
                        getNotification({
                            message: "The file has been successfully edited.",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                // setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            // setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }
        // 
    };


    const getTablesFromStorage = () => {
        const table1ObjectFromStorage = JSON.parse(
            localStorage.getItem(UserService.getUserId() + storageName + "Table1")
        );
        if (table1ObjectFromStorage) {
            setTable1([...table1ObjectFromStorage])
        }
    }

    const getDataFromLibrary = () => {
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data;
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name);
            if (
                outputJSONfromLibrary.file_tags &&
                outputJSONfromLibrary.file_tags != null &&
                outputJSONfromLibrary.file_tags != "null"
            )
                setTagStore(outputJSONfromLibrary.file_tags.split(";"));

            fieldNames &&
                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    if (field) {
                        field.value =
                            outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
                    } else {
                        return "";
                    }
                });
        }
    };


    const handleContentEdit = () => {
        const updatedAgreement = [...createAgreement];
        updatedAgreement.map((item) => {
            updatedAgreement[item.serial] = {
                ...updatedAgreement[item.serial],
                content_text: document.getElementById(item.content_name).innerText,
            };
        });

        setCreatAgreement(updatedAgreement);
    };

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(localStorage.getItem(UserService.getUserId() + storageName))
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString);
            setTagStore(masterStorageObject.tags);
            setRenameString(masterStorageObject.renameString);
            setTagStore(masterStorageObject.tags);
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || "";
                }
            });

            // if (masterStorageObject.mode === "Pro") toggleSwitch()
        }
    };
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value;
        const masterStorageObject = {};

        fieldNames &&
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                const value = field ? field.value : "";
                masterStorageObject[fieldName] = value;
            });
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem(UserService.getUserId() + storageName, JSON.stringify(masterStorageObject))

    };

    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end");
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end");
            });
        }
    };


    const getScrrenTOLoader = () => {
        const element = document.getElementById("my-agreement-spinner");
        const offset = -200; // Adjust this value to set the desired offset
        if (element) {
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Optionally, you can add smooth scrolling animation
            });
        }
    };
    // const sendData = async (value) => {
    //     setTimeout(() => {
    //         getScrrenTOLoader();
    //     }, 100);

    //     let interval;
    //     getJoke();
    //     interval = setInterval(getJoke, 10000);
    //     let isFieldEmpty = false;
    //     requiredCheck.forEach((ele) => {
    //         const fieldValue = document.getElementById(ele.field).value;
    //         if (!fieldValue) {
    //             dispatch(
    //                 getNotification({
    //                     message: ele.Message,
    //                     type: "default",
    //                 })
    //             );
    //         }
    //         if (fieldValue) isFieldEmpty = true;
    //     });


    //     //this is not automated // manually added
    //     if (document.querySelector(".default-class").value === "") {
    //         dispatch(
    //             getNotification({
    //                 message: "Please fill the Claim(s) Text/ Description of Embodiment",
    //                 type: "default",
    //             })
    //         );
    //     }
    //     //this is not automated // manually added


    //     else if (isFieldEmpty && UserService.isLoggedIn()) {
    //         try {
    //             setLoaderAgreement(true);
    //             const payload = {
    //                 keycloak_user_id: UserService.getUserId(),
    //                 agreement_type: agreementType,
    //                 file_name: renameString,
    //                 file_tags: tagStore.join(",").replaceAll(",", ";").replace(/;$/, ""),
    //                 session_id: ""
    //             };

    //             fieldNames.forEach((fieldName) => {
    //                 const field = document.getElementById(fieldName);
    //                 const value = field ? field.value : "";
    //                 payload[fieldName] = value
    //             });


    //             payload["drawing_master"] = table1

    //             setHandleSubmit(true);
    //             const getResponse = await axios.post(REACT_APP_CREATE_AGREEMENT_API + api, payload,
    //                 {
    //                     headers: {
    //                         Accept: "application/json",
    //                         "Content-Type": "application/json",
    //                         access_token: UserService.getToken(),
    //                     }
    //                 }
    //             );

    //             if (getResponse.status === 200) {
    //                 const text = getResponse.data.agreement_text;
    //                 setCreatAgreement(text);
    //                 clearInterval(interval);
    //                 // getPlanDetails("noRefresh");
    //                 // console.log("Success");
    //                 setID(getResponse.data.id)
    //                 const firstDiv = document.querySelector('.firstDiv');
    //                 firstDiv && setDivHeight(firstDiv.offsetHeight + 65);
    //                 injectDataToProgressStorage(agreementType)

    //             }
    //         } catch (err) {
    //             console.log(err);
    //             clearInterval(interval);
    //             setHandleSubmit(false);
    //             setLoaderAgreement(false);
    //             // console.log("Error", err);
    //             if (err.response && err.response.status === 503) {
    //                 setContentViolationPromt(true)
    //             }
    //             else if (err.response && err.response.status === 401) {
    //                 dispatch(
    //                     getNotification({
    //                         message: "Session expired! Please log in again",
    //                         type: "default",
    //                     })
    //                 );
    //                 setTimeout(() => {
    //                     UserService.doLogin({
    //                         redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
    //                     });
    //                 }, 3000);
    //             } else {
    //                 dispatch(
    //                     getNotification({
    //                         message:
    //                             "We are experiencing high demand. Please try after a few minutes.",
    //                         type: "default",
    //                     })
    //                 );
    //             }
    //         }
    //     } else if (!UserService.isLoggedIn()) {
    //         dispatch(
    //             getNotification({
    //                 message: "Session expired! Please log in again",
    //                 type: "default",
    //             })
    //         );
    //         setTimeout(() => {
    //             UserService.doLogin({
    //                 redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}`,
    //             });
    //         }, 3000);
    //     }
    //     setLoaderAgreement(false);
    //     clearInterval(interval);

    // };

    // const sendData = async (value) => {
    //     setTimeout(() => {
    //         getScrrenTOLoader();
    //     }, 100);



    // };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            const text = document.getElementById("edit").innerText;
            setCreatAgreement(text)
            try {
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("input_text", text);
                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/api/export_as_docx`,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                setLoaderDownload(true);
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${downloadDocName + UserService.getUsername()}_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                } else if (getResponse.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
                    }, 3000);
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                dispatch(
                    getNotification({
                        message: "We are experiencing high demand. Please try after a few minutes.",
                        type: "default",
                    })
                );
            }
            setLoaderDownload(false);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI + apiRedirection}` });
            }, 3000);
        }

    };

    const extractFigureComponenteNumbers = () => {
        // Initialize an empty array to store the figure numbers
        const figureNumbers = [];
        const componentNo = [];

        // Iterate over each object in the data array
        table1.forEach((item) => {
            // Check if the item has a figure_no property
            if (item.figure_no) {
                // If yes, push the figure_no value to the figureNumbers array
                figureNumbers.push(item.figure_no);
            }
            item.table && item.table.forEach((items) => {
                if (items.component_no) componentNo.push(items.component_no);
            })
        });

        // Return the array of figure numbers
        setFigure([...figureNumbers])
        setComponent([...componentNo])
    }

    const handleInputChange = (e, tableIndex, field, table) => {
        const { value } = e.target;
        if (table === "table1") {
            setTable1((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex][field] = value;
                return updatedTable;
            });
            extractFigureComponenteNumbers()
        }
    };
    const handleInputChangeForRows = (e, tableIndex, rowIndex, columnName, table) => {
        const { value } = e.target;
        if (table === "table1") {
            setTable1((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex].table[rowIndex][columnName] = value;
                extractFigureComponenteNumbers()
                return updatedTable;
            });
        }
    };

    const handleAddRow = (tableIndex, table) => {
        if (table === "table1") {
            setTable1((prevTable) => {
                const updatedTable = [...prevTable];
                updatedTable[tableIndex].table.push({
                    component_no: "",
                    component_name: "",
                    component_description: "",
                    component_connected_component: ""
                });
                return updatedTable;
            });
        }
    };

    const handleRemoveLastRow = (tableIndex, table) => {
        if (table === "table1") {
            setTable1((prevTable) => {
                const updatedTable = [...prevTable];
                // Remove the last row from the table
                updatedTable[tableIndex].table.pop();
                return updatedTable;
            });
        };
    }
    const handleAddTable = (table) => {
        if (table === "table1") {
            setTable1((prevTable) => [
                ...prevTable,
                {
                    figure_no: "",
                    figure_description: "",
                    figure_label: "",
                    visibility: false,
                    table: [
                        {
                            component_no: "",
                            component_name: "",
                            component_description: "",
                            component_connected_component: ""
                        }
                    ]
                }
            ]);
        }
    };
    const handleRemoveLastTable = (table) => {
        if (table === "table1") {
            setTable1((prevTable) => {
                // Remove the last element from the table1 array
                const updatedTable = [...prevTable];
                updatedTable.pop();
                return updatedTable;
            });
        }
    };



    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = "";
            }
        });

        const inputFields = document.querySelectorAll('input');

        // Loop through each input element and set its value to an empty string
        inputFields.forEach((input) => {
            input.value = '';
        });
        setTable1([
            {
                figure_no: "",
                figure_description: "",
                figure_label: "",
                visibility: false,
                table: [
                    {
                        component_no: "",
                        component_name: "",
                        component_description: "",
                        component_connected_component: ""
                    },
                ]
            },
        ])
        setRenameString("New File");
        let temp = [];
        setTagStore(temp);
    };

    const getJoke = async () => {
        try {
            const response = await axios.get(
                "https://official-joke-api.appspot.com/random_joke"
            );
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline,
            });
        } catch (err) {
            console.log("error", err);
        }
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input");
        let tempArr = [...tagStore];
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        } else {
            tempArr.push(tag.value);
        }
        setTagStore(tempArr);
        tag.value = "";
    };

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore];
        if (value) tempArr = tempArr.filter((e) => e !== value);
        setTagStore(tempArr);
    };
    // const handleInputChange = (event) => {
    //     event.target.value = event.target.value.toUpperCase();
    // };
    const removeRow = (index, keyInd) => {
        setTable1((prevTable) => {
            let updatedTable = [...prevTable];
            updatedTable[index].table = updatedTable[index].table.filter((_, i) => i !== keyInd);
            return updatedTable;
        });
    }
    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="xl:grid xl:grid-cols-4 xl:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                                    <div className={handleSubmit ? "d-none" : "row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                        <div className='col-sm'>
                                            {/* <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span> */}
                                            <h4 className='mx-5 text-primary'>{agreementType}</h4>
                                        </div>
                                        <div className='col-sm items-center float-end'>
                                            <Link
                                                to="/app"
                                                // spiketip-title="Go back to home page" spiketip-pos="bottom"

                                                className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                &#8592; Home
                                            </Link>
                                            <button
                                                spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    setNote3(true)
                                                }}
                                                className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-redo mr-2"></i>
                                                Reset Form
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                                    <div className="row menu-bar mt-1 container-fluid">
                                        <div className={handleSubmit ? "" : `col-sm px-2`}>
                                            <button className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"}`} >
                                                <input
                                                    id="input-file-name"
                                                    className={rename ? "float-start w-4/6" : "d-none"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") setRename(false);
                                                    }}
                                                    onChange={(e) => {
                                                        setRenameString(e.target.value);
                                                    }}
                                                    defaultValue={renameString}
                                                    type="text"
                                                    style={{
                                                        // width: "150px",
                                                        height: "30px",
                                                        color: "black",
                                                    }}
                                                />
                                                <span
                                                    onClick={() => {
                                                        if (rename) setRename(false);
                                                        else setRename(true);
                                                    }}
                                                    id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                    className={rename ? "d-none" : "float-start text-primary"}>
                                                    {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                                </span>
                                                <span className="ml-2 float-end">
                                                    |
                                                    <i
                                                        onClick={() => {
                                                            if (rename) setRename(false);
                                                            else setRename(true);
                                                        }}
                                                        className={
                                                            rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                        }
                                                    >
                                                    </i></span>

                                                {/* <i className="fas fa-times"></i> */}
                                            </button>
                                        </div>
                                        <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        getTagButtonFromArray(e.target.value)
                                                    }
                                                }}
                                                placeholder="Add tag here"
                                                className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                                style={{
                                                    height: "35px",
                                                    // fontSize: handleSubmit ? "14px" : "",
                                                    width: "110px",
                                                    border: "2px solid #007BFF",
                                                    textAlign: "left",
                                                }}
                                                type="text"
                                            />
                                            <button
                                                spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                                onClick={() => getTagButtonFromArray()}
                                                id="add-tag"
                                                className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                            >
                                                <i className="fas fa-plus plus-position"></i>
                                            </button>

                                            <span className="scrollable-span mx-1 mt-1 pb-1 w-4/6">
                                                {tagStore.length > 0 &&
                                                    tagStore.map((ele, ind) => (
                                                        <button
                                                            style={{ borderRadius: "9999px" }}
                                                            key={ind}
                                                            className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                            onMouseEnter={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.remove("d-none")
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.add("d-none")
                                                            }}
                                                        >
                                                            {ele}
                                                            <i
                                                                // style={{ display: "none" }}
                                                                onClick={() => { removeTagFromArray(ele) }}
                                                                className="fas fa-window-close ml-1 d-none"></i>
                                                        </button>
                                                    ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white sm:p-4 fw-bold">
                                        <div className="mx-4">
                                            <span>
                                                <input className="cursor-pointer" onChange={() => checkFigure("case-3")} type="radio" id="decider3" name="fav_language" value="HTML" defaultChecked />
                                                <label className="ml-2 mb-2 cursor-pointer" for="decider3">More than one figure or drawing is used</label>
                                            </span>
                                            <br />
                                            <span>
                                                <input className="cursor-pointer" onChange={() => checkFigure("case-2")} type="radio" id="decider2" name="fav_language" value="HTML" />
                                                <label className="ml-2 mb-2 cursor-pointer" for="decider2">One figure or drawing is used</label>
                                            </span>
                                            <br />
                                            <span>
                                                <input className="cursor-pointer" onChange={() => checkFigure("case-1")} type="radio" id="decider1" name="fav_language" value="HTML" />
                                                <label className="ml-2 mb-2 cursor-pointer cursor-pointer" for="decider1">No figures and drawings are used</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white sm:p-4 -mt-6 firstDiv">
                                        <div className="grid grid-cols-3 gap-1 px-2">
                                            {formdata &&
                                                formdata.map((ele, i) => (
                                                    <div key={i} className={"col-span-6 sm:col-span-3 px-1"}>
                                                        <label
                                                            htmlFor={ele.id}
                                                            className={`block text-sm font-medium text-${ele.element === "section-heading" ? "primary text-lg" : "black"}
                                                                }`}
                                                        >
                                                            {ele.name}{" "}
                                                            <span
                                                                className={`${ele.required ? "text-danger" : "d-none"
                                                                    }`}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        {
                                                            ele.element === "input-text" &&
                                                            (
                                                                <textarea
                                                                    style={{ height: '5rem' }}
                                                                    // type={ele.type ? ele.type : "text"}
                                                                    // type={"textarea"}
                                                                    placeholder={ele.placeholder}
                                                                    name={ele.nameAtt}
                                                                    id={ele.id}

                                                                    className={`mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm`}
                                                                >
                                                                </textarea>
                                                            )
                                                        }
                                                        {/* table goes here */}
                                                        {
                                                            ele.element === "input-table-custom-drawing" &&
                                                            <div className="col-span-6 sm:col-span-3 mt-1 overflow-x-auto px-2">
                                                                {
                                                                    ele.table && table1.map((tableElement, index) => (
                                                                        <div key={index} className="col-span-6 sm:col-span-3 mt-1 overflow-x-auto px-2 disable-div">
                                                                            <label
                                                                                htmlFor={tableElement.id}
                                                                                className={`block text-sm font-medium text-${tableElement.element === "section-heading" ? "primary" : "black"} .disable-case2}`}
                                                                            >
                                                                                Figure No :
                                                                                <span
                                                                                    className={`${tableElement.required ? "text-danger" : "d-none"
                                                                                        }`}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            {
                                                                                (
                                                                                    <input
                                                                                        defaultValue={tableElement.figure_no}
                                                                                        onChange={(e) => handleInputChange(e, index, "figure_no", "table1")}
                                                                                        type={tableElement.type ? ele.type : "text"}
                                                                                        placeholder={tableElement.placeholder}
                                                                                        name={tableElement.nameAtt}
                                                                                        id={tableElement.id}

                                                                                        className={`mt-1 block w-1/3 rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm disable-case2`}
                                                                                    // disabled={ele.active}
                                                                                    />
                                                                                )
                                                                            }
                                                                            <label
                                                                                htmlFor={tableElement.id}
                                                                                className={`block text-sm font-medium mt-1 text-${tableElement.element === "section-heading" ? "primary" : "black"}
                                                                        }`}
                                                                            >
                                                                                Figure Description :
                                                                                <span
                                                                                    className={`${tableElement.required ? "text-danger" : "d-none"
                                                                                        }`}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            {
                                                                                ele.element === "input-table-custom-drawing" &&
                                                                                (
                                                                                    <input
                                                                                        defaultValue={tableElement.figure_description}
                                                                                        onChange={(e) => handleInputChange(e, index, "figure_description", 'table1')}
                                                                                        type={tableElement.type ? ele.type : "text"}
                                                                                        placeholder={""}
                                                                                        name={tableElement.nameAtt}
                                                                                        id={tableElement.id}

                                                                                        className={`mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm`}
                                                                                    // disabled={ele.active}
                                                                                    />
                                                                                )
                                                                            }
                                                                            <label
                                                                                htmlFor={tableElement.id}
                                                                                className={`block text-sm font-medium mt-1 text-${tableElement.element === "section-heading" ? "primary" : "black"}
                                                                        }`}
                                                                            >
                                                                                Figure Label :
                                                                                <span
                                                                                    className={`${tableElement.required ? "text-danger" : "d-none"
                                                                                        }`}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            {
                                                                                ele.element === "input-table-custom-drawing" &&
                                                                                (
                                                                                    <input
                                                                                        defaultValue={tableElement.figure_label}
                                                                                        onChange={(e) => handleInputChange(e, index, "figure_label", 'table1')}
                                                                                        type={tableElement.type ? ele.type : "text"}
                                                                                        placeholder={"100"}
                                                                                        name={tableElement.nameAtt}
                                                                                        id={tableElement.id}

                                                                                        className={`mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm`}
                                                                                    // disabled={ele.active}
                                                                                    />
                                                                                )
                                                                            }
                                                                            <label
                                                                                htmlFor={tableElement.id}
                                                                                className={`block text-md mt-3 font-medium text-${tableElement.element === "section-heading" ? "primary" : "black"}
                                                                        }`}
                                                                            >
                                                                                Add reference to Figures?
                                                                                <span
                                                                                    className={`${index === 0 ? "text-danger mx-1" : "d-none"
                                                                                        }`}
                                                                                >
                                                                                    *
                                                                                </span>
                                                                            </label>

                                                                            <span>
                                                                                <input onClick={() => {
                                                                                    setTable1((prevTable) => {
                                                                                        const updatedTable = [...prevTable];
                                                                                        updatedTable[index].visibility = true;
                                                                                        return updatedTable;
                                                                                    });
                                                                                }} type="radio" id={"decider1" + index} name={"fav_language" + index} value="HTML" />
                                                                                <label className="mx-2 mb-2" htmlFor={"decider1" + index}>Yes</label>
                                                                                <input onClick={() => {
                                                                                    setTable1((prevTable) => {
                                                                                        const updatedTable = [...prevTable];
                                                                                        updatedTable[index].visibility = false;
                                                                                        return updatedTable;
                                                                                    });
                                                                                }} type="radio" id={"decider2" + index} name={"fav_language" + index} value="HTML" defaultChecked />
                                                                                <label className="mx-2 mb-2" htmlFor={"decider2" + index}>No</label>
                                                                            </span>
                                                                            <table className={tableElement.table.length && tableElement.visibility ? "table table-striped" : "d-none"}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col" className="text-sm">Label or Number <br />(Unit / Component / Element)</th>
                                                                                        <th scope="col" className="text-sm">Name <br />(Unit / Component / Element)</th>
                                                                                        <th scope="col" className="text-sm">Description <br />(Unit / Component / Element)</th>
                                                                                        <th scope="col" className="text-sm">Connections</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        tableElement.table && tableElement.table.map((obj, indkey) => (
                                                                                            <tr className="relative" key={indkey}>
                                                                                                {
                                                                                                    Object.keys(obj).map((key, keyInd) => (
                                                                                                        <th key={keyInd}>
                                                                                                            <textarea
                                                                                                                onChange={(e) => handleInputChangeForRows(e, index, indkey, key, "table1")}
                                                                                                                // type="text"
                                                                                                                placeholder={keyInd === 0 ? "101" : keyInd === 1 ? "The term used here will be used consistently across the specifications to refer to this label." : keyInd === 2 ? "Optional. Include in cases where a description will add value to the embodiments section." : keyInd === 3 ? "Optional. You can add comma separated labels here. For example, if 101 is connected or related to 201 and 205, and you are filling in for 101, insert 201, 205 here." : ""}
                                                                                                                value={obj[key]}
                                                                                                                // name={obj[key]}
                                                                                                                // id={tableInside.table[key].id}

                                                                                                                className="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                                                                                            >
                                                                                                            </textarea>
                                                                                                            <i onClick={() => removeRow(index, indkey)} className="far fa-times-circle text-danger cursor-pointer absolute -right-2 -top-2"></i>
                                                                                                        </th>
                                                                                                    ))
                                                                                                }
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <div className={tableElement.visibility ? "text-center my-3 mx-auto space-x-3" : "d-none"}>
                                                                                <button className="btn btn-outline-primary"
                                                                                    onClick={() => {
                                                                                        handleAddRow(index, "table1")
                                                                                    }}
                                                                                >Add Component
                                                                                </button>

                                                                                {tableElement.table.length !== 0 && <button className="btn btn-outline-danger"
                                                                                    onClick={() => {
                                                                                        handleRemoveLastRow(index, "table1")
                                                                                    }}
                                                                                > Remove Last Component
                                                                                </button>}
                                                                            </div>
                                                                            <hr className="m-0 mb-3" />
                                                                        </div>
                                                                    ))
                                                                }
                                                                <div className="text-center space-x-3 disable-div">
                                                                    <button className="btn btn-primary disable-case2"
                                                                        onClick={() => {
                                                                            handleAddTable("table1")
                                                                        }}
                                                                    >Add Figure
                                                                    </button>
                                                                    {
                                                                        table1.length > 1 &&
                                                                        <button className="btn btn-outline-danger"
                                                                            onClick={() => {
                                                                                setNote2(true)
                                                                            }}
                                                                        > Remove Last Figure
                                                                        </button>}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3 text-center mx-2">
                                            <Link
                                                to="/app"
                                                spiketip-title="Go back to home page" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-outline-dark mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                Home
                                            </Link>
                                            <button
                                                spiketip-title="Save and Proceed" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-primary mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                // onClick={() => { sendData() }}
                                                onClick={() => {
                                                    setNote4(true)
                                                }}
                                            >
                                                Save and Proceed
                                            </button>
                                            {/* <button
                                                spiketip-title="Previous Script" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-outline-primary mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mr-2"
                                                onClick={() => { navigate(previousTo) }}
                                            >
                                                Previous
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title="It can take up to 30 seconds for TipsyTom to script."
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                    <br />
                                    <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                        Mark Twain
                                    </figcaption> */}

                                    <h4 className="font-italic text-center mt-5"> {joke.setup}</h4>
                                    <h5 className="font-italic text-center mt-2"> {joke.punchline}</h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar">
                                            <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                <div className='col-sm'>
                                                    <h5 className='ml-2 text-primary'>{agreementType}</h5>
                                                </div>
                                                <div className='col-sm text-end'>
                                                    <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                    <button
                                                        spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) {
                                                                setContentState(!contentState);

                                                                if (contentState) {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = true;
                                                                    document
                                                                        .getElementById("download")
                                                                        .setAttribute("disabled", true);
                                                                    document
                                                                        .getElementById("downloadbutton2")
                                                                        .setAttribute("disabled", true);
                                                                    // dispatch(
                                                                    //     getNotification({
                                                                    //         message:
                                                                    //             "Edits to files won't be saved in the library; they are only temporary changes made before downloading.",
                                                                    //         type: "default",
                                                                    //     })
                                                                    // );
                                                                } else {
                                                                    // handleContentEdit();
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = false;
                                                                    document
                                                                        .getElementById("download")
                                                                        .removeAttribute("disabled");
                                                                    document
                                                                        .getElementById("downloadbutton2")
                                                                        .removeAttribute("disabled");
                                                                    editFile(dispatch, REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI, apiRedirection, ID)
                                                                }
                                                            }
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to edit the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i
                                                            id="icon"
                                                            className={
                                                                contentState ? "fas fa-edit" : "fas fa-save"
                                                            }
                                                        ></i>
                                                    </button>
                                                    <button
                                                        spiketip-title="Download Formatted Application" spiketip-pos="left"
                                                        id="download"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) download();
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to download the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: `${divHeight + 14}px`,
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement}
                                                <br />
                                            </div>
                                            <div className='text-center'>
                                                <button
                                                    id="downloadbutton2"
                                                    spiketip-title="Download Formatted Application" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button>
                                                {/* <button
                                                    id="downloadbutton2"
                                                    spiketip-title="Download Formatted Application" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mx-2 mt-1 block rounded-md border-gray-300 shadow-sm btn-success sm:text-sm mb-3`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + apiRedirection })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    <span className="flex m-0">
                                                        <img width="20" height="20" src="https://img.icons8.com/clouds/100/download.png" alt="download" />
                                                        Download All Docuemnts{" "}
                                                    </span>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <FooterOthers />
                        {note4 && <NoteForUser detail={true} note={"Save and proceed?"} onConfirm={() => {
                            injectDataToProgressStorage(agreementType)
                            setNote4(false)
                            navigate("/app")
                        }}
                            onCancle={() => setNote4(false)}
                        />}
                        {note && <NoteForUser note={"Include elements that are relevant to the Claims and Description of Embodiments sections of your patent specifications. You don’t need to repeat the details that you include here, and you can recall them to be used consistently in Claims, and Description of Embodiments. If you miss something out here or want to edit later, you can also add/ edit before their use in such other Scripters."} onConfirm={onConfirm} />}
                        {note2 && <NoteForUser detail={true} note={"Are you sure you want to delete all details provided for this Figure?"} onConfirm={() => {
                            handleRemoveLastTable("table1")
                            setNote2(false)
                        }} onCancle={() => setNote2(false)} />}
                        {note3 && <NoteForUser detail={true} note={"This will clear all fields in this Form. Are you sure you want to proceed?"} onConfirm={() => {
                            resetForm();
                            setNote3(false)
                        }} onCancle={() => setNote3(false)} />}
                        {note5 && <NoteForUser detail={true} note={"This will remove all data in Figure 2 onwards. Would you like to proceed?"} onConfirm={() => {

                            const newdiv = document.querySelectorAll(".disable-div");
                            newdiv.forEach((ele) => {
                                // Find all form elements inside the div
                                const formElements = ele.querySelectorAll('input, textarea, select, button');
                                // Loop through the elements and disable them
                                formElements.forEach(element => {
                                    element.disabled = false;
                                    // element.classList.add("text-[#000000]")
                                });
                            })
                            const newEle = document.querySelectorAll(".disable-case2");
                            // Loop through the elements and disable them
                            newEle.forEach(element => {
                                element.disabled = true;
                            });
                            //removing more than 1
                            setTable1((prevTable) => {

                                // Use slice to create a new array with only the first element
                                const updatedTable = prevTable.slice(0, 1);
                                return updatedTable;
                            });
                            setNote5(false)
                        }} onCancle={() => {
                            setNote5(false)
                            document.getElementById("decider3").click()
                        }} />}
                        {note6 && <NoteForUser detail={true} note={"This will remove all data in figures. Would you like to proceed?"} onConfirm={() => {
                            const newEle1 = document.querySelectorAll(".disable-case2");
                            // Loop through the elements and disable them
                            newEle1.forEach(element => {
                                element.disabled = false;
                            });
                            const div = document.querySelectorAll(".disable-div");
                            div.forEach((ele) => {
                                // Find all form elements inside the div
                                const formElements = ele.querySelectorAll('input, textarea, select, button');
                                // Loop through the elements and disable them
                                formElements.forEach(element => {
                                    element.value = ""
                                    element.disabled = true;
                                    // element.classList.add("text-[#000000]")
                                });
                            })
                            //removing more than 1
                            setTable1([
                                {
                                    figure_no: "",
                                    figure_description: "",
                                    figure_label: "",
                                    visibility: false,
                                    table: [
                                        {
                                            component_no: "",
                                            component_name: "",
                                            component_description: "",
                                            component_connected_component: ""
                                        },
                                    ]
                                },
                            ])
                            setNote6(false)
                        }} onCancle={() => {
                            setNote6(false)
                            document.getElementById("decider3").click()
                        }} />}
                    </div>
                </div >
            )
            }
        </div >
    );
}

export default DescriptionofFiguresandDrawings;
import React, { useEffect } from "react";
import "../css/termOfServices.css";
// Add your custom CSS file for styling

const TermOfServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const support = () => {
    const recipientAddress = "talk@tipsytom.com";
    const subjectLine = "TipsyTom |Contact Us";
    const mailtoUrl = `mailto:${recipientAddress}?subject=${encodeURIComponent(
      subjectLine
    )}`;
    const mailLink = document.createElement("a");
    mailLink.href = mailtoUrl;
    mailLink.click();
  };
  return (
    <div className="terms-container">
      <h1 className="text-center">
        <u>User Terms of Services</u>
      </h1>

      <p className="terms-text">
        Lipi Labs Private Limited, a private limited company incorporated under
        the provisions of the Companies Act, 2013 (“<b>Company</b>” or “
        <b>Lipi Labs</b>”) is a company offering several services, including
        Generative AI Technology for generating Contracts, Agreements and Legal
        Documents, Document Review et al through <b>Tipsytom.com</b> and other
        websites (“<b>Website</b>”). The following document shall explain the
        Terms by which the user (“<b>User</b>”) may use any of the Services
        provided by the Company. By accessing or using the Website, including
        any related subdomain, services, website, applications and software
        provided through the Site, including through a mobile device, the User
        confirms that he/she has read, understood, and agree to be bound by this
        User Terms of Service (hereinafter referred to as “
        <b>Terms of Service</b>” or “<b>Terms</b>”). For the avoidance of doubt,
        “<b>User</b>” or “<b>Users</b>” means anyone who accesses and/or uses
        the site and/or the service.
      </p>
      <p>The Terms and any other Terms & Conditions, policies and guidelines issued by us from time to time in connection with products and uploaded on the Website, shall define and govern the User’s rights and obligations, and shall be binding upon the User.</p>
      <strong>1. Definitions</strong>
      <p>
        <ol className="mx-5 " style={{ lineHeight: "20px" }}>
          <li style={{ listStyle: "lower-alpha" }} >
            “<b>Effective date</b>” means the date of acceptance of the Terms by the
            User.
          </li>
          <li style={{ listStyle: "lower-alpha" }}>
            “<b>Privacy Policy</b>” shall mean privacy policy available on the Website
            as may be updated by us from time to time;
          </li>{" "}
          <li style={{ listStyle: "lower-alpha" }}>“<b>Services</b>” shall mean any or all of the services provided by the Company either through its Website or otherwise;</li>{" "}
          <li style={{ listStyle: "lower-alpha" }}>“<b>Terms</b>” means these terms and conditions and all other applicable
            rules, guidelines, policies of Lipi Labs as set out on the Website or
            incorporated herein by way of reference, as may be modified from time to
            time.</li>
        </ol>

      </p>

      <ol>
        <li style={{ listStyle: "number" }}>

          <strong>Term and Termination</strong>
          <br />
          <p>
            <ol>
              <li >1.1. The Terms and the provisions of this document shall subsist
                until the User discontinues his use of the Services through any of
                the prescribed methods laid down; or if the Company at its sole
                discretion terminates the Services;</li></ol>
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong>Acceptance of Terms</strong>
          <p>
            <ol>
              <li> 2.1. By using Company services or products, the User agrees to be
                bound by these Terms. User’s continued use of Company’s services
                constitutes his/her acceptance of these Terms, including any updates
                or modifications that we may make from time to time.</li>
              <li>   2.2. Company reserves the sole right to change, modify, add to or
                remove from the Terms from time to time, without prior notice to the
                User, and the revised Terms shall be posted on the Website.
                Continued use of the Services shall be deemed to be an acceptance of
                the Services.</li>
            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>User Eligibility</u></strong>
          <p>
            3.1. The services are only available to a User who is:
            <br />
            <ol className="mx-5">
              <li style={{ listStyle: "lower-alpha" }}>competent to enter into legally binding contracts under the
                Applicable Laws;</li>

              <li style={{ listStyle: "lower-alpha" }}>is minimum 18 (eighteen) years of age;</li> <li style={{ listStyle: "lower-alpha" }}> meets criteria as prescribed under the internal policies which we
                formulate.</li>
            </ol>

            3.2. By using the services and products, the User represents and
            warrants to Company that he satisfies the criteria prescribed under
            Clause 3.1 above. to time.
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Registration</u></strong>
          <p>
            <ol>
              <li> 4.1. Any person who wishes to avail the Services will have to
                register as a User and create an account for which he shall furnish
                to the Company all the requested information, and any other
                information that is true, correct, accurate and complete in all
                aspects as required from time to time (“<b>User Account</b>”).</li>
              <li>    4.2. The User shall not share their password, or let a third party
                access their account, or consent to performance of any action, or
                perform any action that may directly or indirectly lead to security
                breach of their account.</li>
              <li>4.3. Certain sections, features, or content on the Website may be
                accessible only to users who have registered an account with the
                Website.</li>
            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Refund and Pricing Policy</u></strong>
          <p>
            <ol>
              <li>
                5.1. No refunds shall be made to the User upon discontinuation or
                cancellation of the subscription. Upon discontinuation of the
                Services or subscription, the Company shall terminate the
                Services/subscription provided to the User only upon the fulfillment
                of the period of subscription.
                <br />(Example: if subscribed for the month of March, then upon
                cancellation of subscription on 16th March, the services shall
                continue till the end of March and shall be discontinued from the
                month of April)
              </li>
              <li>
                5.2. The Company reserves all the rights to change the subscription
                fee at any point in time without prior notice or intimation to the
                User.
              </li>
            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Electronic Transactions and Disclosures</u></strong>
          <p>
            <ol>
              <li>6.1. The User agrees to use NEFT/RTGS/UPI and/or any other
                electronic modes of payment. The User acknowledges that these
                methods of payment are secure, efficient, and convenient, and agrees
                to bear any fees or charges associated with their use. Company will
                provide the User with all necessary instructions and information for
                using NEFT/RTGS and other electronic modes of payment.</li>
              <li> 6.2. The Company may refuse any electronic transaction that it deems
                to be fraudulent or suspicious.</li>
              <li>    6.3. Company will not be liable for any losses or damages arising
                from electronic transactions that are the result of User’s fault,
                fraud, or other illegal activity.</li>
            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Privacy</u></strong>
          <ol>
            <li>
              7.1. Privacy Policy and Terms together constitute an agreement
              between the User and the Company.
            </li>
            <li>
              7.2. In case of any conflict between the provisions of this Clause
              and the Privacy Policy, the provisions of the Privacy Policy will
              prevail.
            </li>
          </ol>

        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Restriction & Responsibilities</u></strong>
          <p> 8.1. User shall not, directly or indirectly:
            <ol className="mx-5">

              <li style={{ listStyle: "lower-alpha" }}>reverse engineer, decompile, disassemble, or otherwise attempt to
                discover the source code, object code, or underlying structure,
                ideas, know how or algorithms relevant to the services or any
                software, documentation, or data related to the services;</li>
              <li style={{ listStyle: "lower-alpha" }}>Access all or any part of the Services to build a product or
                service that competes with the services;</li>
              <li style={{ listStyle: "lower-alpha" }}>Use the services or Software in a manner that, in the reasonable
                opinion of Company, is abusive, illegal, or threatens the integrity
                or performance of the services;</li>
              <li style={{ listStyle: "lower-alpha" }}>Attempt to gain unauthorized access to the website or engage in
                any activity that would compromise the website’s security;</li>
              <li style={{ listStyle: "lower-alpha" }}>Post, upload, or distribute any content that infringes the rights
                of other parties, including copyrights, trademarks, trade secrets,
                or other intellectual property rights;</li>
              <li style={{ listStyle: "lower-alpha" }}>Submit false, misleading, or inaccurate information; and</li>
              <li style={{ listStyle: "lower-alpha" }}>Any other restriction or responsibility that the Company may
                prescribe for the User from time to time.</li>


            </ol>


          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Limitation of Liability</u></strong>
          <p>
            9.1. The User agrees and acknowledges that:
            <br />
            <ol className="mx-5">
              <li style={{ listStyle: "lower-alpha" }}>The Company has no responsibility to ensure the availability of
                the product or services;</li>
              <li style={{ listStyle: "lower-alpha" }}>The Company’s liability with respect to product or services shall
                be limited solely to the extent as set out in the Terms;</li>
              <li style={{ listStyle: "lower-alpha" }}>The Company shall not, in any manner, be liable to the User for
                any delays or incorrect provision, allotment, or non-receipt of the
                product or services to the User, as the case may be; and</li>
              <li style={{ listStyle: "lower-alpha" }}>The Company shall not be responsible, in any manner whatsoever,
                for any costs, expenses, fees, charges, loss, or damages incurred by
                the User for a failed, delayed, or incomplete issuance of the
                product or rendering of the services.</li>
            </ol>

            9.2. To the maximum extent permitted by Applicable law, the Company
            assumes no liability or responsibility for any (i) Errors, mistakes,
            or inaccuracies of Services; (ii) personal injury or property
            damage, of any nature whatsoever, resulting from User’s access to
            and use of Company’s service; (iii) any unauthorized access to or
            use of Company’s secure servers and/or any and all personal
            information stored therein; (iv) any interruption or cessation of
            transmission to or from the service; (v) any bugs, viruses, Trojan
            horses, or the like that may be transmitted to or through our
            service by any third party; (vi) any errors or omissions in any
            content or for any loss or damage incurred as a result of the use of
            any content posted, emailed, transmitted, or otherwise made
            available through the service; and/or (vii) User content or the
            defamatory, offensive, or illegal conduct of any third party. In no
            event shall the Company, its affiliates, directors, employees, or
            licensors be liable to the User for any claims, proceedings,
            liabilities, obligations, damages, losses, or costs.
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Termination</u></strong>
          <p>
            10.1. The Company may at any time suspend and/or terminate, in whole
            or in part, activities performed by it in relation to services or
            product (including as set out hereunder) for any reason whatsoever,
            including for reasons of breach by the User of the Terms, agreed
            and/or any of the policies made available on the website.
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Governing Law and Jurisdiction</u></strong>
          <p>
            11.1. Any dispute arising out of these Terms shall be governed by
            the laws of the Republic of India without regards to its conflict of
            law provisions. Any legal action, suit, or proceeding relating to
            these terms shall be brought to the courts of Bangalore.
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Notices</u></strong>
          <p>
            12.1. All notices, requests, demands, waivers, complaints, queries,
            and other communications required or permitted to be given under the
            Terms in relation to the products availed by the User shall be
            delivered as follows: (i) for notices to the Company, emailed at
            <a className="writetous" onClick={() => { support() }}> <u>info@contractreader.co</u></a> or delivered to the Company’s address, and
            (ii) for notices to the User, to the address registered with the
            Company.
          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Intellectual Property</u></strong>
          <p>
            <ol>
              <li>13.1. The User acknowledges that the Company is the sole and
                rightful owner of the Intellectual Property in the Services, which
                includes, without limitation, the copyrights, patents, trademarks,
                and trade secrets in the artificial intelligence generated content,
                and the User agrees not to register, use, or file in its own name or
                in the name of any other person or company any trademarks same or
                similar or resembling in any manner to the Intellectual Property and
                not to associate the Intellectual Property with its own business.</li>
              <li>   13.2. The grant of rights to the User under this Clause 13 shall not
                be construed to confer upon the User any license, right, or
                interest, by implication or otherwise, in any intellectual property,
                materials, or proprietary rights not expressly granted or licensed
                under this Agreement.</li>
              <li> 13.3. Subject to these Terms, the Company grants the User a
                non-exclusive, personal, perpetual, irrevocable, worldwide,
                royalty-free, and fully transferable and sub-licensable right and
                license to use, reproduce, distribute, perform, transmit, create
                derivatives, modify, adapt, transform or sublicense AI generated
                content to a third party without prior approval of the Company.</li>
              <li> 13.4. All rights not expressly granted under this Agreement are
                reserved by Lipi Labs, its affiliates, or licensors, as the case may
                be. No additional rights or licenses may be implied or inferred by
                or on behalf of the User from any course of dealing, custom or usage
                or trade, or otherwise.</li>
              <li>  13.5. All the content on the Website and all information and
                material in relation to the product(s) are the copyright of the
                Company, except for any third-party content and any link to
                third-party websites.</li>
            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>General Terms</u></strong>
          <p>
            <ol>
              <li>
                14.1. The User agrees and acknowledges that if the User does not
                agree to the applicability of the Terms or any other policy
                available on the Website, then the User shall not avail the products
                or services and discontinue using its User Account.
              </li>
              <li>
                14.2. The website and any content provided thereon are provided on
                an "as is" and "as available" basis, without any warranties of any
                kind, either express or implied. Lipi Labs and TipsyTom disclaims
                all warranties, including, but not limited to, implied warranties of
                merchantability, fitness for a particular purpose, non-infringement,
                and title.
              </li>
              <li>
                14.3. If, at any time, any provision of the Terms is or becomes
                illegal, invalid, or unenforceable in any respect under any law of
                any jurisdiction, the legality, validity, or enforceability of the
                remaining provisions will not be affected or impaired in any manner.
                In such an event, the Company shall have the right to amend the
                Terms, such that the commercial intent of the Terms and the
                interests of the Company are protected.
              </li>
              <li>
                14.4. The User consents and agrees to comply with the guidelines,
                instructions, and/or requests in relation to the products or
                services as may be made by the Company from time to time.
              </li>
              <li>
                14.5. It is agreed between the User and the Company that it is
                solely the responsibility of the User to be aware of the Applicable
                Laws and regulations of the User’s state of residence.
              </li>

            </ol>



          </p>
        </li>
        <li style={{ listStyle: "number" }}>
          <strong><u>Disclaimer</u></strong>
          <p>
            <ol>
              <li>
                15.1. The Company is not a registered Law firm, or an Attorney, and
                these Terms or the use of the Services do not establish an
                Attorney-Client relationship between the Company and User,
                respectively. The Information contained on the website, or any of
                the services or products which you may avail, shall not constitute
                Legal Advice. Please read this document carefully before access, and
                on any use by accessing, registering at the site, and or using
                Company services, User agree to be bound by the Terms as set forth
                above. If User does not wish to be bound by these Terms, Users are
                should not access or register with the Company and/or engage in any
                activity conducted by the Company. If Users violate any of these
                Terms (which include by reference Privacy policy), the Company shall
                reserve the right to initiate Legal Proceedings, terminate User
                access to any of the Services under these Terms, delete the profile
                and any content or information that the User may have posted on the
                site and/or prohibit the User from using or accessing the service or
                the site (or any portion, aspect, or feature of the Service or the
                Site), at any time in its sole discretion, without notice.
              </li>
              <li>15.2. The Services of the Company are not meant for anyone who is
                not a Lawyer, Advocate, attorney, or a barrister at law, or
                associated with the legal industry in similar capacities. Therefore,
                Users who do not fall into the aforesaid categories are strongly
                recommended against using the Services.</li>
              <li>
                15.3. The information provided on the Website is intended solely to
                provide general information for the personal, non-commercial use of
                the reader, who accepts full responsibility for its use. The Company
                does not represent or endorse the accuracy or reliability of any
                information, or advertisements contained on, distributed through, or
                linked, downloaded or accessed from the Website, or the quality of
                any products, information, or other materials displayed, or obtained
                by the User as a result of an advertisement or any other information
                or offer in or in connection with the products provided on the
                Website.
              </li>
            </ol>



          </p>
        </li>
      </ol>
    </div>
  );
};
export default TermOfServices;
